import React from "react";
import Slider from "react-slick";
import {Link} from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';
import { API_ROOT, IMAGE_URL } from '../../Services/Api-config';
 
const initialState = {latestproduct:[]}
class ProductSlider extends React.Component {

  constructor(props) {
		super(props);
		this.state = initialState;
	}
	componentDidMount = () => {
		axios.get(`${API_ROOT}weblatestproduct`)
		.then(response => {
				// console.log(response.data.success);
				   this.setState({ latestproduct: response.data.success });
			
		})
		.catch(function (error) {
		   console.log(error);
		})
		
	} 


  render() {
    var settings = {
      dots: true,
      infinite: true,
      autoplay:true,
      autoplaySpeed: 3000,
      slidesToShow: this.state.latestproduct.length-1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <Slider {...settings}>
        {this.state.latestproduct.length >0 && this.state.latestproduct.map((object, i) =>
        <div className="Proslider" key={i}>
          <div>
            <div className="container">
              <a href={object.urlname}>
              <div>
              <div className="inner-proslider">
                <img src={IMAGE_URL + object.image} />
              </div>
                {object.productname}
              </div>
              </a>
            </div>
          </div>
        </div>
        )}
        
        </Slider>
    );
  }
}
export default ProductSlider;