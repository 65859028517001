import React from 'react'
import {Link} from 'react-router-dom'
class TermsAndCondition extends React.Component{
render(){
return(
<div class="About">
   <div className="container">
      <div className="row">
         <div className="col-md-12">
            <div className="terms-condition">
               <h3>HOME  TERMS & CONDITIONS</h3>
               <p>Use of the Site</p>
               <ul>
                  <li>
                     These terms of use (“Terms”) apply to all and any
                      use of <Link to="/termsandcondition">
                     www.???????????.com </Link> (“the Site”).
                     In accessing the Site, you agree to do so only for your own personal, 
                     non-commercial use and in accordance with these Terms. Please read these
                     Terms carefully as they set out your rights and obligations and the 
                     terms on which we make the Site available to you. They also provide 
                     information on how you can submit material and how this will be used.
                  </li>
                  <li>
                     The Site is owned and operated by Rana Abdul Rahim & partners
                     LLC (“we” or “us”). Rana Abdul Rahim & partners LLC is a limited
                     liability company registered in the sultanate of Oman with its 
                     registered office address at Al Qurum, Oman P.O.box 1031.
                  </li>
                  <li>
                     Your use of the Site constitutes your binding acceptance of these 
                     Terms. If these Terms are not accepted in full, you should not 
                     access the Site and should stop using it immediately. We reserve 
                     the right to change these Terms at any time by posting the modified 
                     terms on the Site and it is your responsibility to refer to and comply
                     with the most up to date Terms on accessing the Site. Your continued 
                     use of the Site after changes are posted constitutes your acceptance
                     of the latest Terms.
                  </li>
                  <li>
                     Unless otherwise stated, you may not copy, reproduce, republish, 
                     download, post, store (including in any other website), distribute,
                     transmit, broadcast, commercially exploit or modify in any way any 
                     of the Site’s material or content (including other Contributions 
                     (as defined below) or any advertising or sponsorship), or permit or
                     assist any third party to do the same.
                  </li>
                  <li>
                     You agree to only use the Site for lawful purposes and in a manner 
                     that does not improperly infringe the rights of, or restrict or inhibit
                     the use and enjoyment of, the Site by any third party. Such restriction 
                     or inhibition includes, but is not limited to, conduct which is libelous,
                     in breach of anyone’s privacy, or which may harass, cause real distress 
                     or inconvenience to any person. You further agree that you shall not 
                     (by way of example and without limitation), reformat or frame any portion 
                     of the web pages that are part of the Site; copy or modify the HTML code 
                     used to generate web pages on the Site; use any device, software, or procedure 
                     that interferes with the proper working of the Site, or otherwise attempt 
                     to interfere with the proper working of the Site; take any action that imposes,
                     or may impose in our sole discretion, an unreasonable or disproportionately 
                     large load on our IT infrastructure; modify, adapt, translate or reverse engineer
                     any portion of the Site; disrupt or otherwise interfere with the Site or the 
                     networks or servers used by us; impersonate any person or entity or misrepresent 
                     your connection or affiliation with a person or entity; or solicit, collect or 
                     store, or attempt to solicit, collect or store, personal information about other 
                     Site users.
                  </li>
                  <li>
                     The Site may include links to third party web sites from time to time, including 
                     without limitation, social media websites such as Facebook, Instagram (“Third Party Sites”).
                     Third Party Sites with links from the Site have not been verified or reviewed by us. Your 
                     access and use of any Third Party Site is entirely at your own risk and discretion and subject
                     to the terms and conditions and privacy policies of that website. You acknowledge and agree 
                     that: (a) we are not responsible for the availability or accessibility of Third Party Sites 
                     or the content, products and/or services offered through such Third Party Sites; (b) our 
                     providing links to Third Party Sites shall not be interpreted as endorsement or approval 
                     by us of the operators of the Third Party Sites, the organizations sponsoring the Third
                     Party Sites, or any products or services offered via the Third Party Sites; (c) we do 
                     not guarantee the accuracy, completeness or authenticity of information, resources, or 
                     content available on or through Third Party Sites; and (d) we shall not be responsible 
                     or liable, either directly or indirectly, for any damage or loss caused or alleged to be 
                     caused in connection with your use or reliance on any such content, information, statements
                     , representations, advertising, products, services or other materials available on or 
                     through Third Party Sites. If you decide to access Third Party Sites, resources or 
                     services, or transact with third parties 
                     for their products or services, you do so entirely at your own risk.
                  </li>
                  <li>
                     Some parts of the Site may have specific terms of use (e.g. competitions, contests and campaigns).
                     By registering for and/or using these parts of the Site you are agreeing to these specific terms.
                     If there is any conflict between the Terms and specific terms appearing on the Site, the specific 
                     terms shall prevail.
                  </li>
                  <li>
                     <p>Other Content and Complaints</p>
                     If you become aware of any misuse of the Site or wish to complain about any Contribution or material
                     on the Site or believe any of your rights have been violated e.g. your copyright infringed, you have 
                     been defamed etc., you can email us at info@????????.Com In order for us to deal with your complaint 
                     effectively, 
                     we ask that you provide us with the following information:
                  </li>
                  <li className="term-dot-line"> <span>•</span> Nature of your complaint and location within the Site of the particular content or Member.</li>
                  <li className="term-dot-line"><span>•</span> For copyright disputes, identification of the copyrighted work you claim has been infringed and a 
                     statement that you have a good-faith belief the disputed use is not authorized by the copyright owner, its agent, or
                     the law and that you are the owner of the copyright interest 
                     involved or are authorized to act on behalf of the owner.
                  </li>
                  <li className="term-dot-line"><span>•</span>  Your name, address, telephone number and email address and such other information as we may r
                     easonably request.
                  </li>
                  <li>Availability of the Site, Third Party Sites and Liability
                     The Site, Contributions and information, images, logos and content relating to the Site, Rana Abdul Rahim & partners LLC, 
                     and/or any of our associated companies, products and services (or to third party products and services), are provided ‘AS IS’ 
                     and on an ‘AS AVAILABLE’ basis without any representation, endorsement or warranty of any kind, including but not limited to 
                     implied warranties of satisfactory quality, fitness for a particular purpose, non-infringement, 
                     compatibility, security and accuracy of the Site and/or Third Party Sites.
                  </li>
                  <li>We will endeavor to provide the Site using all reasonable care and will use reasonable efforts
                     to make the Site available to you at all times. However, you acknowledge that the Site is provided
                     over the internet and so the quality and availability of the Site may be affected by factors that 
                     are outside our reasonable control such as technical faults in your ISP’s and/or telecommunication 
                     providers’ network/service. We are not responsible for any problems or technical malfunction of any
                     telephone network or lines, computer on-line systems, servers, providers, computer equipment or 
                     software, or failure of any email to be received on account of technical problems or traffic 
                     congestion on the Internet, telephone lines or at any website (or any combination of these things).
                  </li>
                  <li>
                     We make no guarantees that the Site will be available, uninterrupted or error free,
                     or that defects will be corrected, or that the Site or the server that makes it available
                     are free of viruses or bugs.
                  </li>
                  <li>
                     Content, information and other material on the Site may contain inaccuracies and typographical
                     errors. We do not warrant the accuracy or completeness of these or the reliability of any 
                     information displayed or distributed through the Site (including provided through any software).
                     You acknowledge that reliance on any such information shall be at your sole risk. We reserve 
                     the right, in our sole discretion, to correct any errors or omissions in any part of the Site 
                     and to make changes to the Site and to the materials, products, programs, services, content or 
                     prices described in the Site at any time without notice.
                  </li>
                  <li>
                     We cannot accept any responsibility for any damage, loss, injury or disappointment suffered through use of the Site. In no event will we or any of our third party affiliates or Rana Abdul Rahim & partners LLC personally be liable for any damages whatsoever, whether in action of contract, tort (including negligence), strict liability or other action, including (but not limited to) damages for loss of use of the Site, unauthorized access, use or alteration of your transmissions or contributions, damage to equipment or other software, or loss of data or profits or for other monetary loss or for any indirect, consequential damages, incidental, special, exemplary, or punitive damages arising out of or in connection with the use (or inability to use) or performance of the Site, even if we have been advised of the possibility of such damages. Because some jurisdictions may not allow the exclusion or limitation of liability for consequential or incidental damages, the above limitation may not apply to you. In addition, in no event shall we or any licensors or third party affiliates be liable for any unauthorized use of the Site. If you are dissatisfied with any portion of the Site, or with any of these Terms, your sole and exclusive remedy is to discontinue using the Site and the services.
                  </li>
                  <li>
                     <p>Intellectual Property Rights Including Copyright</p>
                     The names, images and logos identifying Rana Abdul Rahim & partners LLC, all its associated companies or third parties and any products and services are proprietary marks of these parties. Nothing in these Terms or other specific terms appearing on the Site shall be construed as conferring to you any license or right under any intellectual property right of all the above parties unless expressly stated otherwise.
                  </li>
                  <li>All copyright, trademarks and other intellectual property rights in the Site (including the design, arrangement and look and feel) and all material, software or content supplied as part of the Site, other than your Contributions and Your Data (as included in the Privacy Policy below), shall remain at all times our property or the property of our licensors and is protected by copyright law.</li>
                  <li>Any products and services on the Site are subject to availability and are available at our sole discretion.</li>
                  <li>
                     <p>General</p>
                     The Terms shall be governed by and construed in accordance with the laws of the Oman. Any disputes arising from the Site or Terms will be decided only by the Oman courts.
                  </li>
                  <li>If any provision of these Terms are held by any court of competent authority to be unlawful, invalid or unenforceable, in whole or in part, this will not affect the validity of the remaining Terms which will continue to be valid and enforceable to the fullest extent permitted by law.</li>
                  <li>You agree to indemnify and keep indemnified us from and against all claims, damages, expenses, costs and liabilities (including legal fees) relating to or arising from your use or arising from any breach or suspected breach of the Terms by you or your violation of any law or the rights of any third party.</li>
                  <li>We can transfer our rights and obligations under the Terms to any company, firm or person.</li>
                  <li>Our failure to exercise or enforce any right or provision of the Terms shall 
                     not constitute a waiver of such 
                     right or provision unless acknowledged and agreed to by us in writing.
                  </li>
                  <li>
                     If you have any questions or concerns about this Site, Terms or Privacy Policy, please contact us by email at 
                     <Link to="/termsandcondition">
                     info??????.com</Link>, or by writing to us at Rana Abdul Rahim & partners LLC Head Office & Logistics, ???????????????. P.O. Box 1031
                  </li>
                  <li>
                     <p>Privacy Policy</p>
                     This privacy policy (together with our Terms of Use and any other documents referred to in it) sets out the basis on which any personal information we collect from you, or that you provide to us, will be processed by us. Please read the following policy carefully to understand our views and practices regarding your personal information and how we will treat it. By visiting this website (the “Site”), you are accepting and consenting to the practices described in this policy.
                  </li>
                  <li className="term-dot-line">
                     <p>Information we may collect about you</p>
                     <p>We may collect and process the following information about you:</p>
                     <span>• </span>Personal information you provide us, when you register on our Site, subscribe
                     to our services, place an order, participate in discussion boards or other social media functions, enter a competition, promotion or survey, post a Contribution, contact us, or if you report a problem with the Site. Such information may include your name, address, e-mail address and phone number, financial and credit card information, personal description and photograph or any other information
                     from which you may be personally identified (“Personal Information”).
                  </li>
                  <li className="term-dot-line"><span>•</span> Non-Personal Information we automatically collect when you visit the Site, such as technical information detailing your visits to the Site including but not limited to traffic data, location data and other communication data.</li>
                  <li className="term-dot-line"><span>•</span> Information we receive from other sources, for example if you use any of the other websites we operate or the other services we provide. In this case we will have informed you when we collected that data that it may be shared internally and combined with data collected on this Site. We also work closely with third parties (including, for example, business partners, sub-contractors in technical, payment and delivery services, advertising networks, analytics providers, search information providers, credit reference agencies) and may receive information about you from them, which we may combine with information you give to us and information we collect about you.</li>
                  <li>
                     <p className="content-line">How we may use your information</p>
                     we use the information collected and held about you primarily to provide you with the services you have requested when you register to use the Site.
                  </li>
                  <li>
                     <p>We also use the information we collect to:</p>
                  </li>
                  <li className="term-dot-line"><span>•</span>  Carry out our obligations arising from any contracts entered into between you and us;</li>
                  <li className="term-dot-line"><span>•</span>   Notify you about any changes to the service;</li>
                  <li className="term-dot-line"><span>•</span>   Run promotional activity;</li>
                  <li className="term-dot-line"><span>•</span>   Ensure that content from our Site is presented in the most effective manner for you and for your computer;</li>
                  <li className="term-dot-line"><span>•</span>  Administer the Site and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes;</li>
                  <li className="term-dot-line"><span>•</span>   Allow you to participate in any interactive features of our Site;</li>
                  <li className="term-dot-line"><span>•</span>   Keep the Site safe and secure;</li>
                  <li><span>•</span>  Measure or understand the effectiveness of advertising we serve to you and others, and to deliver relevant advertising to you; and</li>
                  <li><span>•</span>  Provide you with the information, products and services that you request from us or which we feel may interest you (except where you have requested not to be contacted for such purposes).</li>
                  <li>
                     <p>Disclosure of your data</p>
                     We may share your information with any member of our group, which means our subsidiaries, our ultimate holding company and its subsidiaries. We may also share your information with selected third parties including:
                  </li>
                  <li>Our agents, business partners, advisors, suppliers and sub-contractors for the performance of any contract we enter into with them or you.</li>
                  <li>Advertisers and advertising networks to select and serve relevant adverts to you and others. We do not disclose information about identifiable individuals to our advertisers, but we may provide them with aggregate information about our users.</li>
                  <li>Our partners that sponsor or promote any competition that we conduct or promote via the Site.</li>
                  <li>Our partners for the purpose of contacting you directly about their offers, promotions, goods or services (if you have requested to be contacted for such purposes).</li>
                  <li>Analytics and search engine providers that assist us in the improvement and optimization of the Site.</li>
                  <li>In the event that we sell or buy any business or assets, in which case we may disclose your personal information to the prospective seller or buyer of such business or assets.</li>
                  <li>If we or substantially all of our assets are acquired by a third party, in which case personal information held by us will be one of the transferred assets.</li>
                  <li>If we are under a duty to disclose or share your personal information in order to comply with any legal obligation, or in order to enforce or apply our terms of use and other agreements; or to protect the rights, property, or safety of us, our customers, or others.</li>
                  <li>
                     Marketing Communications
                     by submitting your details anywhere on this Site, our brands’ sites, or our social media platforms, you agree that we may process your personal information for marketing purposes. You agree that we may use your information to contact you by e-mail and/or SMS about offers, events, products or related services that you may find useful. If you do not wish to receive marketing communications from us, you can unsubscribe at any time by following the unsubscribe link in any marketing communications received from us, or by contacting us at 
                     <Link>
                     ????????????????????</Link>
                  </li>
                  <li>
                     <p>Third party websites</p>
                     The Site may, from time to time, contain links to and from the websites owned and/or controlled by third parties. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for the privacy practices of such third-party websites. Please check these policies before you submit any personal information to these websites.
                  </li>
               </ul>
               <p>CONTACT US</p>
               <p className="last-line-condition"> <Link to="privacy-policy">PRIVACY POLICY</Link> | <Link to="/termsandcondition">TERMS & CONDITIONS</Link> | <Link to="/">SITE MAP</Link> </p>
            </div>
         </div>
      </div>
   </div>
</div>
)
}
}
export default TermsAndCondition;