import React from 'react'
import {Link} from 'react-router-dom'
import { Redirect } from 'react-router';
import ShippingForm from './../common/shipping-form'

class Shipping extends React.Component{
	constructor(props){
		super(props);
  }

  componentDidMount(){
	window.scrollTo({
		top: 0,
		behavior: "smooth"
	 });
  }
	render(){
		return(
			<div className="About">
			{	 !localStorage.getItem('webuser')?<Redirect to='/login' />:'' }
				<section class="contact-img-area">
		            <div class="container">
		                <div class="row">
		                    <div class="col-md-12 text-center">
		                        <div class="con-text">
		                            <h2 class="page-title">Shipping</h2>
		                            <p><a href="#">Home <i class="fa fa-angle-right"></i></a> Shipping</p>
		                        </div>
		                    </div>
		                </div>
		            </div>
		        </section>
		        <section class="design-area home-five" >
				  	<div class="container">
				  		<ShippingForm />		   
				  	</div>
				</section>
			</div>
			)
	}
}
export default Shipping;