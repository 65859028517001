import React from 'react'
import {Link} from 'react-router-dom'
import {connect} from "react-redux"
import axios from 'axios';
import { API_ROOT, IMAGE_URL } from '../../Services/Api-config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from 'react-router'

const initialState = { userid: 0, couponCode:"", couponCodeError:"", couponloading:false, couponsuccesmsg:"", useCouponCode:"", useCouponType:"", useCouponValue:"", useCouponeCodeId:"" }
class Cart extends React.Component{
   constructor(props){
		super(props);
      this.state = initialState;
      this.handleSubmit = this.handleSubmit.bind(this);
  }

   notify = () => toast.error("Please login for shipping items!");

  componentWillMount() {
   if (localStorage.getItem("webuser") != undefined) {
      const userID = JSON.parse(localStorage.getItem("webuser"));
      this.setState({ userid: userID.id })
   }

   if(this.props.data.totalPrice>0){
      if(sessionStorage.getItem('useCouponeCode')!=undefined){ this.setState({useCouponCode : sessionStorage.getItem('useCouponeCode')}) }
      if(sessionStorage.getItem('useCouponeCodeId')!=undefined){ this.setState({useCouponeCodeId : sessionStorage.getItem('useCouponeCodeId')}) }
      if(sessionStorage.getItem('useCouponType')!=undefined){ this.setState({useCouponType : sessionStorage.getItem('useCouponType')}) }
      if(sessionStorage.getItem('useCouponValue')!=undefined){ this.setState({useCouponValue : sessionStorage.getItem('useCouponValue')}) }
   }else{
      sessionStorage.removeItem('useCouponeCode')
      sessionStorage.removeItem('useCouponeCodeId')
      sessionStorage.removeItem('useCouponType')
      sessionStorage.removeItem('useCouponValue')
   }
  

}
  
  componentDidMount(){
   window.scrollTo({
      top: 0,
      behavior: "smooth"
   });
   //  console.log(this.props.data.shoppingCart)
  }

  incrimentitem(id) {
   const { dispatch, incproduct, itemlist } = this.props
   this.props.incproduct(id);
   this.props.data.shoppingCart.map(object => {
      if (object.productid === id) {
         // this.setState({ totalqty: object.qty })
         if (this.state.userid != 0) {
         var datapost = {'userid':this.state.userid, 'product_id':id, qty:object.qty}
         axios.put(`${API_ROOT}cartupdate/`+id, datapost).then(response => {
            console.log(response);
         }).catch(error => {
            console.log(error);
         });
      }
         }
   })
}

decproductitem(id) {
   const { dispatch, incproduct, itemlist } = this.props
   this.props.data.shoppingCart.map(object => {
      if (object.productid === id) {
         // this.setState({ totalqty: object.qty })
         this.props.decproduct(id);
         if (this.state.userid != 0) {
         var datapost = {'userid':this.state.userid, 'product_id':id, qty:object.qty}
         axios.put(`${API_ROOT}cartupdate/`+id, datapost).then(response => {
            console.log(response);
            }).catch(error => {
               console.log(error);
            });
         }
      }
   })
}

deleteproductitem(id){
   const { dispatch, incproduct, itemlist } = this.props
   this.props.deleteproduct(id);
   this.props.data.shoppingCart.map(object => {
      if (object.productid === id) {
         if (this.state.userid != 0) {
         var datapost = {'userid':this.state.userid, 'productid':id}
         axios.post(`${API_ROOT}cartdelete`, datapost).then(response => {
            console.log(response);
            }).catch(error => {
               console.log(error);
            });
         }
      }
   })
}

gotoshiping(e){
  const shoppingCart = this.props.data.shoppingCart;
  const webuser = JSON.parse(localStorage.getItem('webuser'));
  if (webuser.id) {
   const datapost = {'userid':webuser.id, 'cartitem':shoppingCart}
    axios.post(`${API_ROOT}cartitemsupdate`, datapost).then(response => {
      //   console.log(response);
        if(response.data.success=='success'){
         //   console.log(this.props)
        this.props.history.push("/shipping")
         // this.props.history.push("/");
         
        }
        }).catch(error => {
            console.log(error);
        });
      }
}


validate = () => {
   let couponCodeError = "";

   if (this.state.couponCode=="") {
      couponCodeError = "coupon Code can not be blank";
   }

   if (couponCodeError) {
      this.setState({ couponCodeError });
      return false;
   }

   return true;
};

handleSubmit(event) {
   event.preventDefault();
   const isValid = this.validate();
   // console.log(isValid);
      if (isValid) {
      this.state.couponloading = true;
      this.setState({ couponCodeError: "" })

      axios.post(`${API_ROOT}customercouponredeemcoupon`, { couponcode: this.state.couponCode, userid: this.state.userid }).then(response => {
         // console.log(response);
        if(response.data.error){
            this.setState({couponCodeError: response.data.error})
        }else{
           if(response.data.coupondetail!=null){
            sessionStorage.setItem('useCouponeCode',response.data.coupondetail.couponcode );
            sessionStorage.setItem('useCouponeCodeId',response.data.coupondetail.id );
            sessionStorage.setItem('useCouponType', response.data.coupondetail.coupontype );
            sessionStorage.setItem('useCouponValue', response.data.coupondetail.couponvalue );
            this.setState({ isUpdate: false, couponsuccesmsg: 'coupon used Successfully', couponCodeError:'', useCouponCode:response.data.coupondetail.couponcode, useCouponValue:response.data.coupondetail.couponvalue, useCouponType:response.data.coupondetail.coupontype })
           }else{
            this.setState({couponCodeError: 'coupon not valid'})
           }
        
        }
         this.setState({ couponloading: false });
      }).catch(error => {
         console.log(error);
      });   

      }
}

	render(){
		return(
			<div className="About">
   <section class="contact-img-area">
      <div class="container">
         <div class="row">
            <div class="col-md-12 text-center">
               <div class="con-text">
                  <h2 class="page-title">Shopping cart</h2>
                  <p><a to="/cart">Home <i class="fa fa-angle-right"></i></a> Shopping cart</p>
               </div>
            </div>
         </div>
      </div>
   </section>
   <section class="design-area home-five" >
      
   {this.props.data.shoppingCart.length >0 ?
      <div class="container">
         <div class="row">
            <div class="col-lg-12 col-12">
               <div class="s-cart-all">
                  <div className="form-title" id="title">
                     <h2>Shopping cart</h2>
                  </div>
                  <div class="cart-form table-responsive">
                     <table cellpadding="0" cellspacing="0" class="data-table cart-table">
                        <tr>
                           <th>Product</th>
                           <th >Name</th>
                           <th>Price</th>
                           <th>Quantity</th>
                           <th>Total</th>
                           <th>&nbsp; </th>
                        </tr>
                        {this.props.data.shoppingCart.length ? this.props.data.shoppingCart.map(product => (
                        <tr >
                           <td className="horizontal-content">
                              <Link to={`/product-details/${product.slug}`} className="side-content">
                              <img src={IMAGE_URL+product.thumb} alt="Product-image" className="img-fluid" />
                              </Link>
                           </td>
                           <td class="sop-cart">
                             {product.productname}
                           </td>
                           <td><span class="amount">OMR: {product.price}</span></td>
                           <td>
                              <div class="qty2 qty3">
                                 <input type="button"  onClick={()=>{this.decproductitem(product.productid)}} name="subtract"  value="-" />
                                 <input type="text" name="qty" id="qty" readonly value={product.qty} />
                                 <input type="button"  onClick={()=>{this.incrimentitem(product.productid)}} name="add" value="+" />
                              </div>
                           </td>
                           <td><span class="amount">OMR: {product.qty * product.price}</span></td>
                           <td>
                              <Link  onClick={()=>{this.deleteproductitem(product.productid)}}>
                              <img src="images/remove.png" /></Link>
                           </td>
                        </tr>
                          )) : 'Yourr Cart is currently empty!'}
                     </table>
                     
                  </div>
                  <div class="last-check1">
                     <div class="yith-wcwl-share yit">
                        <p class="checkout-coupon an-cop">
                           Order Summary
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="second-all-class">
            <div class="row">
               <div class="col-lg-6 col-md-12 col-12">
                  <div class="tb-tab-container2">
                     <ul class="nav etabs" role="tablist">
                        <li class="vc_tta-tab active">Use Coupon Code</li>
                     </ul>
                     <div class="tab-content another-cen">
                        <div role="tabpanel" class="tab-pane " id="home">
                           <div class="top-shopping4">
                              <p class="shop9">Shipping Local Pickup (Free)</p>
                              <p class="down-shop">Enter your destination to get a shipping estimate</p>
                           </div>
                           <form action="#" class="woocommerce-shipping-calculator">
                              <p class="form-row form-row-wide">
                                 <label>
                                 Country
                                 <span class="required">*</span>
                                 </label>
                                 <select class="email s-email s-wid">
                                    <option>Bangladesh</option>
                                    <option>Albania</option>
                                    <option>Åland Islands</option>
                                    <option>Afghanistan</option>
                                    <option>Belgium</option>
                                 </select>
                              </p>
                              <p class="form-row form-row-wide">
                                 <label>
                                 District 
                                 <span class="required">*</span>
                                 </label>
                                 <select class="email s-email s-wid">
                                    <option>mymensingh</option>
                                    <option>dhaka</option>
                                    <option>khulna</option>
                                    <option>kumillah</option>
                                    <option>chadpur</option>
                                 </select>
                              </p>
                              <p class="form-row form-row-wide">
                                 <label>
                                 Post Code  
                                 <span class="required">*</span>
                                 </label>
                                 <input class="form-control" type="text" name="name" required placeholder="1234567" />
                              </p>
                              <p class="checkout-coupon two">
                                 <input type="submit" value="Get Quotes" />
                              </p>
                           </form>
                        </div>
                        <div role="tabpanel" class="tab-pane active" id="profile">
                           <div class="2nd-copun-code">
                              <form onSubmit={this.handleSubmit}>
                                 <p class="form-row form-row-wide">
                                    <label>
                                    Coupon:  
                                    <span class="required">*</span>
                                    </label>
                                    <input class="form-control again" value={this.state.couponCode} type="text" name="name" required placeholder="Coupon code" onChange={(event) => { this.setState({ couponCode: event.target.value }) }} />
                                    <div style={{ fontSize: 12, color: "red" }}>
                                          {this.state.couponCodeError}
                                       </div>
                                 </p>
                                 <p class="checkout-coupon full">
                                  {this.state.couponloading?<img src="/images/6.gif" />:<input type="submit" value="Apply Coupon" />}  
                                 </p>
                              </form>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-lg-5 col-md-12 col-12 pull-right">
                  <div class="sub-total">
                     <table>
                        <tbody>
                           <tr class="cart-subtotal" >
                              <th>Sub Total:</th>
                              <td>
                                 <span class="amount">OMR: {this.props.data.totalPrice}</span>
                              </td>
                           </tr>
                           {this.props.data.totalPrice>0?<>  <tr>
                           <th>Coupone Type</th>
                           <td>
                              {this.state.useCouponType==1?'Fixed':''}
                              {this.state.useCouponType==2?'%':''}
                           </td>
                           </tr>
                           <tr>
                           <th>Coupone Value</th>
                           <td>
                              {this.state.useCouponValue}
                           </td>
                           </tr></>:''}
                         
                           <tr class="order-total">
                              <th>Total:</th>
                              <td>
                                 {this.props.data.totalPrice>0?
                                 <strong>
                        <span class="amount"><strong>
                           {this.state.useCouponType==1?<>OMR: {this.props.data.totalPrice - parseInt(this.state.useCouponValue)}</>:<></>}
                           {this.state.useCouponType==2?<>OMR: {(this.props.data.totalPrice - (this.props.data.totalPrice/100)*parseInt(this.state.useCouponValue)).toFixed(0)}</>:<></>}
                           {this.state.useCouponType==""?<>OMR: {this.props.data.totalPrice}</>:<></>}
                           </strong></span>
                                 </strong>:'$0'
   }
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
                  <div class="wc-proceed-to-checkout">
                    
                     {!localStorage.getItem('webuser')? <Link class="button wc-backward" to="/login?page=cart" >PROCEED TO CHECKOUT</Link>: <Link class="button wc-backward" onClick={(e)=>this.gotoshiping(e)}>
                     PROCEED TO CHECKOUT </Link>}
                        <ToastContainer position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover />
                     <Link class="button wc-backward pull-right b1" to="/sub-category">CONTINUE SHOPPING</Link>
                  </div>
               </div>
            </div>
         </div>
      </div>
      : <div style={{textAlign:"center"}}>
      <img style={{width: "25%"}} src="/images/empty-cart.png" />
      <h3>There is no item in your cart. Kindly add item into your cart.</h3>
      <Link to="/sub-category" style={{fontSize:"25px"}}>shop now</Link>
      </div>
       }
   </section>
</div>
			)
	}
}

const mapsStateToProps=(state)=>{
   return {
     data: state
   }
 }
 
 const mapDispatchToProps = (dispatch)=> {
   return {
     deleteproduct: id =>
       dispatch({
         type: "DELETE_PRODUCT",
         productid: id
       }),
       incproduct: id =>
       dispatch({
         type: "INC",
         productid: id
       }),
       decproduct: id =>
       dispatch({
         type: "DEC",
         productid: id
       })
 
   };
 }
 
 export default connect(mapsStateToProps, mapDispatchToProps)(withRouter(Cart));