import React from 'react'
import { Link } from 'react-router-dom'
// import { AiFillStar } from "react-icons/ai";
// import Tabb from './tabs'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { AiFillFacebook } from "react-icons/ai";
import { AiOutlineTwitter } from "react-icons/ai";
import { FaPinterest } from "react-icons/fa";
import { GiCancel } from "react-icons/gi";
import $ from 'jquery'
import axios from 'axios';
import { API_ROOT, IMAGE_URL } from '../../Services/Api-config';
import { connect } from "react-redux"
import Zoom from "./../common/zoom-img"
import StarRatings from 'react-star-ratings';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const initialState = { productItem: [], product: "", productimages: [], totalqty: 0, backgroundPosition: '0% 0%', src : '', backgroundImage: "", tabIndex: 0, userid: 0, imagechange:"", reviewcount:0,setreview:0 }
class Products extends React.Component {
constructor(props) {
super(props);
this.state = initialState;
}
componentWillMount() {
if (localStorage.getItem("webuser") != undefined) {
const userID = JSON.parse(localStorage.getItem("webuser"));
this.setState({ userid: userID.id })
}
}
componentDidMount() {
console.log(this.props.itemlist)
console.log(this.props.subcategory)
console.log(this.props.parentcategory)
this.setState({ productItem: this.props.itemlist })
}
quickviewshow(i, productdetail) {
   console.log(productdetail)
   let setreview = productdetail.totalreviews!=0?parseFloat(productdetail.totalreviews/productdetail.reviews):0;
this.setState({tabIndex:0, reviewcount:productdetail.reviews, setreview:setreview })
let productqty = [];
productqty = this.props.data.shoppingCart.filter(item => {
return item.productid === productdetail.productid
})
productqty.length > 0 ? this.setState({ totalqty: productqty[0].qty }) : this.setState({ totalqty: 0 });
axios.get(`${API_ROOT}customerwebproductgallerybyid/` + productdetail.productid)
.then(response => {
this.setState({ productimages: response.data.productimages, imagechange:IMAGE_URL+response.data.productimages[0].size_704x440 });
})
.catch(function (error) {
console.log(error);
})
this.setState({ product: productdetail })
// console.log(this.state.product);
$(".outer-quick-content").toggleClass("show-quick-content");
$("#root").toggleClass("show-quick-box");
}
quickviewclose(i) {
   this.setState({productimages:[]})
$(".outer-quick-content").removeClass("show-quick-content");
$("#root").toggleClass("show-quick-box");
}
hidecss(i) {
$(".outer-quick-content").removeClass("show-quick-content");
$("#root").toggleClass("show-quick-box")
}
incrimentitem(id) {
   const { dispatch, incproduct, itemlist } = this.props
   
   this.setState({ totalqty: this.state.totalqty+1 })
   this.props.data.shoppingCart.map(object => {
   if (object.productid === id) {
      this.props.incproduct(id);
      if (this.state.userid != 0) {
         var datapost = {'userid':this.state.userid, 'product_id':id, qty:object.qty}
         axios.put(`${API_ROOT}cartupdate/`+id, datapost).then(response => {
         // console.log(response);
         }).catch(error => {
         console.log(error);
         });
      }
   }
   })
}
decproductitem(id) {
const { dispatch, incproduct, itemlist } = this.props
this.props.decproduct(id);
this.props.data.shoppingCart.map(object => {
if (object.productid === id) {
this.setState({ totalqty: object.qty })
if (this.state.userid != 0) {
var datapost = {'userid':this.state.userid, 'product_id':id, qty:object.qty}
axios.put(`${API_ROOT}cartupdate/`+id, datapost).then(response => {
console.log(response);
}).catch(error => {
console.log(error);
});
}
}
})
}
addtocartproductitem(productid, productItem) {
   let currentqty = this.state.totalqty!==0?this.state.totalqty:1;
   this.props.addtocartproduct(productid, productItem, currentqty);
   this.setState({totalqty :currentqty})
   toast.success("Product Successfully added to Cart!")
   if (this.state.userid != 0) {
      const datapost = {'userid':this.state.userid, 'product_id':productid, qty:1}
      axios.post(`${API_ROOT}cartsave`, datapost).then(response => {
      console.log(response);
      }).catch(error => {
      console.log(error);
      });
   }
}

incrimentproductitem(){
   this.setState({totalqty: this.state.totalqty+1})
}

handleMouseMove(e, image) {
// console.log(image);
this.setState({ backgroundImage:`url(${IMAGE_URL+image})` })
const { left, top, width, height } = e.target.getBoundingClientRect()
const x = (e.pageX - left) / width * 100
const y = (e.pageY - top) / height * 100
this.setState({ backgroundPosition: `${x}% ${y - 10}%` })
}
iamgezoom(objectimage){
// console.log(objectimage);
this.setState({imagechange:objectimage})
}
render() {
return (
<div className="product-box">
         <ToastContainer position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover />
   <div className="row">
      {
      this.props.itemlist.map((object, i) => {
      return (
      <div className="col-md-4 mb-4" key={i}>
         <div className="inner-product border">
            <img src={IMAGE_URL + object.imagename} className="img-fluid" />
            <Link className="product-price-name" to={`/product-details/${this.props.parentcategory!=undefined?this.props.parentcategory:object.perantslug}/${this.props.subcategory!=undefined?this.props.subcategory:object.subcategoryslug}/${object.slug}`} >
            {object.productname}</Link>
            <br />
            <p  className="price"> OMR: {object.price}</p>
            <Link className="quick-view" onClick={i =>
            this.quickviewshow(i, object)}>quick view </Link>
         </div>
      </div>
      )
      })
      }
      <div className="outer-quick-content">
         <div className="container">
            <div className="quick-content">
               <div className="row">
                  <div className="col-md-7">
                     <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex =>
                        this.setState({ tabIndex })} >
                        {this.state.productimages.map((object, i) =>
                        <TabPanel key={i}>
                           <Zoom zoomScale={3}
                              height={400}
                              width={580}
                              src={this.state.imagechange}
                              transitionTime={0.5} />
                        </TabPanel>
                        )}
                        <TabList>
                           {this.state.productimages.map((object, i) =>
                           <Tab key={i}><img src={IMAGE_URL + object.thumb} onClick={(e)=>{this.iamgezoom(IMAGE_URL + object.size_704x440)}} /></Tab>
                           )}
                        </TabList>
                     </Tabs>
                     <div className="product-b-icon">
                        <p>
                           <span className="fb">
                              <AiFillFacebook />
                           </span>
                           <span>share</span>
                           <span className="tweet">
                              <AiOutlineTwitter />
                           </span>
                           <span>tweet</span>
                           <span className="pin-it">
                              <FaPinterest />
                           </span>
                           <span>pin it</span>
                        </p>
                     </div>
                  </div>
                  <div className="col-md-5">
                     <div className="star-box">
                       <p className="star-icon"> 
                           <StarRatings
                              starRatedColor="orange"
                              rating={(this.state.setreview)}
                              starDimension="19px"
                              starSpacing="1px"
                           /> <span> {this.state.reviewcount} reviews</span></p>

                        <h2> {this.state.product.productname}</h2>
                        <p className="p-price">OMR: {this.state.product.price}</p>
                        <p className="stock-title">avialability : {this.state.product.productstatus != 1 ? <span>Out of Stock</span> : <span>In Stock</span>}</p>
                        {this.state.totalqty>0? 
                        <p className="q-title">quality : 
                        <p><span onClick={() => { this.decproductitem(this.state.product.productid) }}>-</span><span>{this.state.totalqty}</span><span onClick={() => { this.incrimentitem(this.state.product.productid) }}>+</span></p>
                        </p> 
                        :
                        <p className="q-title">quality : 
                        <p><span >-</span><span>{this.state.totalqty}</span><span  onClick={() => { this.incrimentproductitem() }}>+</span></p>
                        </p>
                        }
                        <div className="buy-btn">
                           <Link onClick={() =>
                           { this.addtocartproductitem(this.state.product.productid, this.props.itemlist) }}>add to cart</Link>
                           <Link to="/cart" onClick={() =>
                           { this.addtocartproductitem(this.state.product.productid, this.props.itemlist) }}>buy now</Link>
                           
                        </div>
                        <Link className="pro-details" to={`/product-details/${this.props.parentcategory!=undefined?this.props.parentcategory:this.state.product.perantslug}/${this.props.subcategory!=undefined?this.props.subcategory:this.state.product.subcategoryslug}/${this.state.product.slug}`} onClick={i =>
                        this.hidecss(i)}>full Product details</Link>
                        
                     </div>
                  </div>
               </div>
               <Link className="quick-view quick-view1" onClick={i =>
                        this.quickviewclose(i)}>
                        <GiCancel />
                        </Link>
            </div>
         </div>
         
      </div>
   </div>
</div>
)
}
}
const mapsStateToProps = (state) => {
return {
data: state
}
}
const mapDispatchToProps = (dispatch) => {
return {
addtocartproduct: (id, product, totalqty) =>
dispatch({
type: "ADD_TO_CART",
productid: id,
totalqty: totalqty,
products: product
}),
incproduct: id =>
dispatch({
type: "INC",
productid: id
}),
decproduct: id =>
dispatch({
type: "DEC",
productid: id
})
};
}
export default connect(mapsStateToProps, mapDispatchToProps)(Products);