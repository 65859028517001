
import React from "react";
import Slider from "react-slick";
import {Link} from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';
import { API_ROOT, IMAGE_URL } from '../../Services/Api-config';
 
const initialState = {sliderlist:[]}
class SimpleSlider extends React.Component {

  
  constructor(props) {
		super(props);
		this.state = initialState;
	}
	componentDidMount = () => {
		axios.get(`${API_ROOT}webbanner`)
		.then(response => {
				// console.log(response.data.success);
				   this.setState({ sliderlist: response.data.success });
			
		})
		.catch(function (error) {
		   console.log(error);
		})
		
	} 

  render() {
    var settings = {
      dots: true,
      autoplay:true,
      autoplaySpeed: 3000,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <Slider {...settings}>
        
        { this.state.sliderlist.length >0 && this.state.sliderlist.map((object, i) =>
        <div>
          <div className="slider">
            <img src={IMAGE_URL + object.banner_one} alt="lalas slider img"/>
            <div className="container">
              {/* <div className="inner-slider">
                <p className="banner-text">new collection</p>
                <h1>lalas products</h1>
                <p className="banner-subtext">save upto 50 % off</p>
                <Link to="/product">shop now</Link>
              </div> */}
            </div>
          </div>
        </div>
        )}
        {/* <div>
          <div className="slider">
            <img src="images/img2.jpg" alt="lalas slider img"/>
            <div className="container">
              <div className="inner-slider">
                <p className="banner-text">new collection</p>
                <h1>lalas products</h1>
                <p className="banner-subtext">save upto 50 % off</p>
                <Link to="/">shop now</Link>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="slider">
            <img src="images/img3.jpg" alt="lalas slider img"/>
            <div className="container">
              <div className="inner-slider">
                <p className="banner-text">new collection</p>
                <h1>lalas products</h1>
                <p className="banner-subtext">save upto 50 % off</p>
                <Link to="/">shop now</Link>
              </div>
            </div>
          </div>
        </div> */}
      </Slider>
    );
  }
}
export default SimpleSlider;