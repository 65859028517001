import React from 'react'
import { Link } from 'react-router-dom'
import { AiFillFacebook } from "react-icons/ai";
import { AiOutlineTwitter } from "react-icons/ai";
import { FaPinterest } from "react-icons/fa";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import $ from 'jquery'
import axios from 'axios';
import { API_ROOT, IMAGE_URL } from '../../Services/Api-config';
import { connect } from "react-redux"
import ZoomPro from "./../common/zoom-imgpro"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StarRatings from 'react-star-ratings';
import moment from 'moment'

const initialState = { productItems: [], product: [], productset: [], productimages: [], similarProducts: [], totalqty: 0, backgroundPosition: '0% 0%', src: '', backgroundImage: "", tabIndex: 0, userid: 0, imagechange: "", reviewValue: 0, reviewName: "", reviewTitle: "", reviewBody: "", reviewNameError: "", reviewTitleError: "", reviewBodyError: "", reviewValueError: "", successmsg: "", loading: false, productid: "", reviewlist: [], gettotalreview: 0, parentcty: '', subcty: '' }
class ProductDetails extends React.Component {

	constructor(props) {
		super(props);
		this.state = initialState;
		this.handleSubmit = this.handleSubmit.bind(this);
	}


	notify = () => toast.error("Please login for review!");

	componentWillMount() {
		if (localStorage.getItem("webuser") != undefined) {
			const userID = JSON.parse(localStorage.getItem("webuser"));
			this.setState({ userid: userID.id, tabIndex: 0 })
		}
	}

	componentDidUpdate = (prevProps) => {
		if (this.props.match.params.slug !== prevProps.match.params.slug) {
			this.productdetailbyslug(this.props.match.params.slug);
			var seleteditembyslug = this.props.data.shoppingCart.find(item =>
				item.slug == this.props.match.params.slug
			)

			seleteditembyslug != undefined ? this.setState({ totalqty: seleteditembyslug.qty }) : this.setState({ totalqty: 0, tabIndex: 0 });
		}
	}

	componentDidMount = () => {

		this.jquerycall();
		this.productdetailbyslug(this.props.match.params.slug);

		this.setState({ parentcty: this.props.match.params.parentcty.replace("-", " "), subcty: this.props.match.params.subcty.replace("-", " ") })
		var seleteditembyslug = this.props.data.shoppingCart.find(item =>
			item.slug == this.props.match.params.slug
		)
		seleteditembyslug != undefined ? this.setState({ totalqty: seleteditembyslug.qty }) : this.setState({ totalqty: 0, tabIndex: 0 });
	}

	productdetailbyslug(slug) {
		this.setState({ gettotalreview: 0 })
		axios.get(`${API_ROOT}customerwebgetproductbyslug/` + slug)
			.then(response => {
				if (response.data.reviewproducts.length > 0) {
					let total = response.data.reviewproducts.reduce((prev, next) => prev + next.reviewvalue, 0);
					let setreview = parseFloat(parseInt(total) / parseInt(response.data.reviewproducts.length));
					this.setState({ gettotalreview: setreview })
				}

				this.setState({ product: response.data.product[0], productset: response.data.product, similarProducts: response.data.similarProducts, productid: response.data.product[0].productid, reviewlist: response.data.reviewproducts });
				this.getproductimages(response.data.product[0].productid);
				window.scrollTo(0, 0)
			})
			.catch(function (error) {
				console.log(error);
			})
	}

	getproductimages(productid) {
		axios.get(`${API_ROOT}customerwebproductgallerybyid/` + productid)
			.then(response => {
				this.setState({ productimages: response.data.productimages, imagechange: IMAGE_URL + response.data.productimages[0].size_704x440 });

			})
			.catch(function (error) {
				console.log(error);
			})
	}

	jquerycall() {
		$(".quick-view").click(function () {
			$(".outer-quick-content").toggleClass("show-quick-content");

		});
		$(".pro-details").click(function () {
			$(".outer-quick-content").toggleClass("show-quick-content");
		})
		$(document).ready(function () {
			$(window).scroll(function () {
				if ($(document).scrollTop() > 150) {
					$(".Cate2").addClass("test2");
				} else {
					$(".Cate2").removeClass("test2");
				}
			});
		});
		$(document).ready(function () {
			$(window).scroll(function () {
				if ($(document).scrollTop() > 2000) {
					$(".Cate2").removeClass("test2");
				}
				else {

				}
			});
		});
	}

	incrimentitem(id) {
		const { dispatch, incproduct, itemlist } = this.props
		
		this.setState({ totalqty: this.state.totalqty+1 })
		this.props.data.shoppingCart.map(object => {
			if (object.productid === id) {
				this.props.incproduct(id);
				if (this.state.userid != 0) {
					var datapost = { 'userid': this.state.userid, 'product_id': id, qty: object.qty }
					axios.put(`${API_ROOT}cartupdate/` + id, datapost).then(response => {
						// console.log(response);
					}).catch(error => {
						console.log(error);
					});
				}
			}
		})
	}

	decproductitem(id) {
		const { dispatch, incproduct, itemlist } = this.props
		this.setState({ totalqty: this.state.totalqty>1?this.state.totalqty-1:1 })

		this.props.data.shoppingCart.map(object => {
			if (object.productid === id) {
				this.props.decproduct(id);
				if (this.state.userid != 0) {
					var datapost = { 'userid': this.state.userid, 'product_id': id, qty: object.qty }
					axios.put(`${API_ROOT}cartupdate/` + id, datapost).then(response => {
						// console.log(response);
					}).catch(error => {
						console.log(error);
					});
				}
			}
		})
	}

	addtocartproductitem(productid, productItem) {
		let currentqty = this.state.totalqty!==0?this.state.totalqty:1;
		this.props.addtocartproduct(productid, productItem, currentqty);
		toast.success("Product Successfully added to Cart!")
		this.setState({totalqty :currentqty})
		if (this.state.userid != 0) {
			const datapost = { 'userid': this.state.userid, 'product_id': productid, qty: 1 }
			axios.post(`${API_ROOT}cartsave`, datapost).then(response => {
				// console.log(response);
			}).catch(error => {
				console.log(error);
			});
		}
	}

	incrimentproductitem(){
		this.setState({totalqty: this.state.totalqty+1})
	}

	iamgezoom(objectimage) {
		console.log(objectimage);
		this.setState({ imagechange: objectimage })
	}

	WriteReview = () => {
		$(".review-content").toggleClass("show-review-box");
	}

	validation = () => {
		let reviewNameError = "";
		let reviewTitleError = "";
		let reviewBodyError = "";
		let reviewValueError = "";

		if (this.state.reviewName == "") {
			reviewNameError = "Enter review name";
		}

		if (this.state.reviewTitle == "") {
			reviewTitleError = "Enter review Title";
		}

		if (this.state.reviewBody == "") {
			reviewBodyError = "Enter review Body";
		}

		if (this.state.reviewValue == 0) {
			reviewValueError = "Select Review";
		}

		if (reviewNameError != "" || reviewTitleError != "" || reviewBodyError != "" || reviewValueError) {
			this.setState({ reviewNameError, reviewTitleError, reviewBodyError, reviewValueError });
			return false;
		}

		return true;
	}

	handleSubmit(event) {
		event.preventDefault();
		const isValid = this.validation();
		if (isValid) {
			this.setState(
				{ reviewNameError: "", reviewTitleError: "", reviewBodyError: "", reviewValueError: "" }
			)
			this.setState({ loading: true })
			var dataset = { 'userid': this.state.userid, 'productid': this.state.productid, 'reviewvalue': this.state.reviewValue, 'reviewname': this.state.reviewName, 'reviewtitle': this.state.reviewTitle, 'reviewbody': this.state.reviewBody }
			axios.post(`${API_ROOT}saveview`, dataset).then(response => {
				console.log(response);
				if (response.data.Result == "success") {
					this.setState({ loading: false, successmsg: 'Form Submit Successfully', reviewValue: 0, reviewName: "", reviewTitle: "", reviewBody: "" });
					this.componentDidMount();
				}
			}).catch(error => {
				console.log(error);
			});

		}
	}

	changeRating(newRating) {
		alert(newRating);
		this.setState({
			reviewValue: newRating
		});
	}

	render() {
		return (
			<div className="Product">
				<section class="contact-img-area">
					<div class="container">
						<div class="row">
							<div class="col-md-12 text-center">
								<div class="con-text">
									<h2 class="page-title">Product Details</h2>

									<p>	 <Link to={'/'}>Home <i class="fa fa-angle-right"></i></Link>
										<Link to={`/sub-category/${this.props.match.params.parentcty}`}> {this.state.parentcty} </Link>
										<Link to={`/product/${this.props.match.params.parentcty}/${this.props.match.params.subcty}`}> {this.state.subcty}</Link>{this.state.product.productname} </p>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section class="design-area home-five" >
					<div class="container">
						<div className="row">
							<div className="col-md-7" id="Cate-in">
								<div className="Cate2">
									<Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.setState({ tabIndex })}  >
										{this.state.productimages.length > 0 && this.state.productimages.map((object, i) =>
											<TabPanel key={i}>
												<ZoomPro zoomScale={3}
													height={400}
													width={630}
													src={this.state.imagechange}
													transitionTime={0.5} />
											</TabPanel>
										)}
										<TabList>
											{this.state.productimages.length > 0 && this.state.productimages.map((object, i) =>
												<Tab key={i}><img src={IMAGE_URL + object.thumb} onClick={(e) => { this.iamgezoom(IMAGE_URL + object.size_704x440) }} /></Tab>
											)}
										</TabList>
									</Tabs>
									<div className="product-b-icon">
										<p><span className="fb"><AiFillFacebook /> </span><span>share</span><span className="tweet"><AiOutlineTwitter /></span> <span>tweet</span><span className="pin-it"><FaPinterest /></span><span>pin it</span></p>
									</div>
								</div>
							</div>
							<div className="col-md-5">
								<div className="star-box">
									<p className="star-icon"> <StarRatings
										starRatedColor="orange"
										rating={(this.state.gettotalreview)}
										starDimension="19px"
										starSpacing="1px"
									/> <span>{this.state.reviewlist.length} reviews</span></p>

									<h2>{this.state.product.productname}</h2>
									<p className="p-price">OMR: {this.state.product.price}</p>
									<p className="stock-title">avialability : {this.state.product.productstatus != 1 ? <span>Out of Stock</span> : <span>In Stock</span>}</p>
									{this.state.totalqty > 0 ? <p className="q-title">quality : <p><span onClick={() => { this.decproductitem(this.state.product.productid) }} style={{ cursor: 'pointer' }}>-</span><span>{this.state.totalqty}</span><span style={{ cursor: 'pointer' }} onClick={() => { this.incrimentitem(this.state.product.productid) }}>+</span></p></p>
										:
										<p className="q-title">quality : <p><span style={{ cursor: 'pointer' }} >-</span><span>{this.state.totalqty}</span><span style={{ cursor: 'pointer' }} onClick={() => { this.incrimentproductitem() }}>+</span></p></p>
									}



									<div className="buy-btn">
												<Link onClick={() => { this.addtocartproductitem(this.state.product.productid, this.state.productset) }}>add to cart</Link>
												<Link to="/cart" onClick={() => { this.addtocartproductitem(this.state.product.productid, this.state.productset) }}>buy now</Link>
									</div>

									<div className="descr">
										<h2>description</h2>
										<div dangerouslySetInnerHTML={{ __html: this.state.product.productdescription }} />
										<div className="custom-review">
											<h3>customer reviews</h3>
											<div>
												<div className="wrt-review">
													<div>
														<StarRatings
															starRatedColor="orange"
															rating={(this.state.gettotalreview)}
															starDimension="19px"
															starSpacing="1px"
														/>
														<p>based on {this.state.reviewlist.length} revies</p>
													</div>
													<div>

														{!localStorage.getItem('webuser') ? <Link className="write-review-btn" onClick={this.notify}>write review</Link> : <Link className="write-review-btn" onClick={this.WriteReview}>write review</Link>}

														<ToastContainer position="top-right"
															autoClose={2000}
															hideProgressBar={false}
															newestOnTop={false}
															closeOnClick
															rtl={false}
															pauseOnFocusLoss
															draggable
															pauseOnHover />

													</div>


												</div>
												<ul>
													<li className="review-content">
														<div className="review-box">
															<h5>write a review</h5>

															<form onSubmit={this.handleSubmit} >
																<div className="rate">
																	<span>Rate</span>

																	<StarRatings
																		rating={this.state.reviewValue}
																		starRatedColor="blue"
																		changeRating={(e) => this.changeRating(e)}
																		numberOfStars={5}
																		name='rating'
																	/>


																</div>

																<div className="errorMsg">{this.state.reviewValueError}</div>
																<fieldset>
																	<label>name</label>
																	<input type="text" value={this.state.reviewName} onChange={(event) => { this.setState({ reviewName: event.target.value }) }} />
																	<div className="errorMsg">{this.state.reviewNameError}</div>
																</fieldset>
																<fieldset>
																	<label>review title</label>
																	<input type="text" value={this.state.reviewTitle} onChange={(event) => { this.setState({ reviewTitle: event.target.value }) }} />
																	<div className="errorMsg">{this.state.reviewTitleError}</div>
																</fieldset>
																<fieldset>
																	<label>body of review (1500)</label>
																	<textarea value={this.state.reviewBody} onChange={(event) => { this.setState({ reviewBody: event.target.value }) }}  ></textarea>
																	<div className="errorMsg">{this.state.reviewBodyError}</div>
																</fieldset>
																{this.state.loading === true ? <img scr="/images/6.gif" /> : ''}
																<div className="review-btn">
																	<button>submit review</button>
																</div>

															</form>
														</div>
													</li>

													{this.state.reviewlist.length > 0 && this.state.reviewlist.map((object, i) =>


														<li key={i}>
															<p>
																<StarRatings
																	starRatedColor="orange"
																	rating={(parseFloat(object.reviewvalue))}
																	starDimension="19px"
																	starSpacing="1px"
																/>

															</p><span className="tit">{object.firstname} {object.lastname}</span> <br /> <span>{moment(object.created_at).format("dddd, MMMM Do YYYY")}</span>

															<p>{object.reviewbody}</p>
														</li>
													)}


												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section>
					<div className="product-slider">
						<div className="container">
							<div className="product-tile">
								<h2>related products</h2>
								<img src="/images/underline-style-2.png" />
							</div>

							<div className="product-box">
								<div className="row">
									{this.state.similarProducts.map((object, i) =>
										<div className="col-md-3 mb-4" key={i}>
											<div className="inner-product border">
												<img src={IMAGE_URL + object.imagename} className="img-fluid" />
												<p>{object.productname}</p>
												<Link className="price">OMR: {object.price}</Link>
												<Link to={`/product-details/${this.props.match.params.parentcty}/${this.props.match.params.subcty}/${object.slug}`} className="quick-view">quick view </Link>
											</div>
										</div>
									)}
								</div>
							</div>

						</div>
					</div>
				</section>
			</div>
		)
	}
}
// export default ProductDetails;

const mapsStateToProps = (state) => {
	return {
		data: state
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		addtocartproduct: (id, product, totalqty) =>
			dispatch({
				type: "ADD_TO_CART",
				productid: id,
				totalqty: totalqty,
				products: product
			}),
		incproduct: id =>
			dispatch({
				type: "INC",
				productid: id
			}),
		decproduct: id =>
			dispatch({
				type: "DEC",
				productid: id
			})

	};
}

export default connect(mapsStateToProps, mapDispatchToProps)(ProductDetails);


