import React from 'react';
// import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/style.css'
import {BrowserRouter as Router, Switch , Route } from 'react-router-dom'
import Home from './components/pages/home'
import Collection from './components/pages/collection'
import About from './components/pages/about'
import Contact from './components/pages/contact'
// import Kashmir from './components/pages/kashmir'
// import Category from './components/pages/categories'
import Product from './components/pages/product'
import Login from './components/pages/login'
import Register from './components/pages/register'
import Shipping from './components/pages/Shipping'
import Cart from './components/pages/cart'
import ProductDetails from './components/pages/product-details'
import Header from './components/includes/header'
import Footer from './components/includes/footer'
import Forget from './components/pages/forget'
import ChangePassword from './components/pages/ChangePassword'
import SubCategory from './components/pages/sub-category'
import Profile from './components/pages/profile'
import Invoice from './components/pages/invoice'
import Blog from './components/pages/blog'
import PrivacyPolicy from './components/pages/privacy-policy'
import TermsAndCondition from './components/pages/termsandcondition'
import Error from './components/pages/error'
import {connect} from "react-redux"



import PrivateRoute from './Utils/PrivateRoute';
// import Route from './Utils/Route';

// console.log(this.props.data.shoppingCart);

class App extends React.Component{
  constructor(props){
    super(props);
    
  }

  componentWillMount(){
    
    // localStorage.clear();
    let  productstorage =[];
		 productstorage = JSON.parse(localStorage.getItem("shoppingCart"));
		
		 const { dispatch, addtocartproduct } = this.props
		if(productstorage!=null){
			for(var i=0;i<productstorage.length;i++ ){
				this.props.addtocartproduct(productstorage[i].productid, productstorage[i])	
			}				
			
		}
  }
  render() {
  return (
    <Router>
    <div className="App">
      <Header />
      <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/collection" component={Collection} />
      <Route exact path="/about" component={About} />
      <Route  exact path="/contact" component={Contact} />
      <Route exact path="/product" component={Product} />
      <Route exact path="/sub-category" component={SubCategory} />
      <Route exact path="/sub-category/:slug" component={SubCategory} />
      <Route exact path="/product/:parentcty/:slug" component={Product} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/shipping" component={Shipping} />
      <Route exact path="/cart" component={Cart} />
      <Route exact path="/forget" component={Forget} />
      <Route exact path="/ChangePassword" component={ChangePassword} />
      {/* <Route exact path="/product-details" component={ProductDetails} />    */}
      <Route exact path="/product-details/:parentcty/:subcty/:slug" component={ProductDetails} />  
      <Route exact path="/invoice/:slug" component={Invoice} />  
      {/* <Route exact path="/blog" component={Blog} />   */}
      <Route exact path="/blog/:slug" component={Blog} />  
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />  
      <Route exact path="/terms-and-condition" component={TermsAndCondition} /> 
      <PrivateRoute exact path="/profile" component={Profile} />   
      <Route  component={Error} />  
  


      </Switch>
       <Footer />
    </div>
    </Router>
  );
  }
}

const mapsStateToProps=(state)=>{
  return {
    data: state
  }
}

const mapDispatchToProps = (dispatch)=> {
  return {
    addtocartproduct: (id, product) =>
		dispatch({
		  type: "ADD_TO_CART_SESSION",
		  id: id,
		  products: product
		}),
		incproduct: id =>
		dispatch({
		  type: "INC",
		  id: id
		}),
		decproduct: id =>
		dispatch({
		  type: "DEC",
		  id: id
		})

  };
}

export default connect(mapsStateToProps, mapDispatchToProps)(App);
