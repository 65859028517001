/* eslint jsx-a11y/mouse-events-have-key-events: 0 */
import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import styles from './style.css'


class Zoom extends Component {
  constructor (props) {
    super(props)

    this.state = {
      zoom: false,
      mouseX: null,
      mouseY: null
      
    }

    const {
      height,
      img,
      transitionTime,
      width,
    } = props

    this.outerDivStyle = {
      height: `${height}px`,
      width: `${width}px`,
      overflow: 'hidden',
    }



    this.imageRef = createRef()

    this.handleMouseOver = this.handleMouseOver.bind(this)
    this.handleMouseOut = this.handleMouseOut.bind(this)
    this.handleMouseMovement = this.handleMouseMovement.bind(this)
  }

  handleMouseOver () {

    this.setState({
      zoom: true,
    })
  }

  handleMouseOut () {

    this.setState({
      zoom: false,
    })
  }

  handleMouseMovement (e) {
    
    const {
      left: offsetLeft,
      top: offsetTop,
    } = this.imageRef.current.getBoundingClientRect()

    const {
      current: {
        style: {
          height,
          width,
        },
      },
    } = this.imageRef

    const x = ((e.pageX - offsetLeft) / parseInt(width, 10)) * 100
    const y = ((e.pageY - offsetTop) / parseInt(height, 10)) * 100

    this.setState({
      mouseX: x,
      mouseY: y,
    })
  }
 
  render () {
    const {
      mouseX,
      mouseY,
      zoom,
    } = this.state

    const {
      zoomScale,
    } = this.props

    const transform = {
      transformOrigin: `${mouseX}% ${mouseY}%`,
    }

    return (
      <div
        style={this.outerDivStyle}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
        onMouseMove={this.handleMouseMovement}
        ref={this.imageRef} className="img-zoomer"
      >
        <img src={this.props.src} alt="zoom-img" style={{
            ...transform,
            
            transform: zoom ? `scale(${zoomScale})` : 'scale(1.0)',
          }}
          className={styles.zoomImg} />
          <button onClick={this.Changeon}></button>
      </div>

    )
  }
}



export default Zoom
