import React from 'react'
import {Link} from 'react-router-dom'
import SimpleSlider from './../common/slider'
import ProductSlider from './../common/latest-product-slider'
import TestimonialSlider from './../common/testimonial'
// import Product from './../common/prodcut'
import Products from './../common/product1'
// import $ from 'jquery';
import Video from './../common/video'
import MapContainer  from './../common/google-map'
import axios from 'axios';
import { API_ROOT, IMAGE_URL } from '../../Services/Api-config';
// import LoadingOverlay from 'react-loading-overlay';
// import MetaTags from 'react-meta-tags';

const initialState = { productItem:[],categorylist: [], categorychild: [], blogone:"", blogtwo:"", blogthree:"", isActive:true }
class Home extends React.Component{

	constructor(props) {
		super(props);
		this.state = initialState;
	}

	componentDidUpdate= () =>{
			window.scrollTo(0, 0)
	}

	componentDidMount = () => {
		this.setState({isActive:false})
		axios.get(`${API_ROOT}customerwebproducthome`)
		.then(response => {
				console.log(response.data.success);
				   this.setState({ productItem: response.data.success });
			
		})
		.catch(function (error) {
		   console.log(error);
		})
		
		axios.get(`${API_ROOT}webblog`)
		.then(response => {
				// console.log(response.data.success[1]);
				if(response.data.success[0]!=undefined){
					this.setState({blogone:response.data.success[0]})	
				}
				if(response.data.success[1]!=undefined){
					this.setState({blogtwo:response.data.success[1]})	
				}
				if(response.data.success[2]!=undefined){
					this.setState({blogthree:response.data.success[2]})	
				}
					// this.setState({blogone:response.data.success[0], blogtwo:response.data.success[1], blogthree:response.data.success[2]})
				//    this.setState({ bloglist: response.data.success });
			
		})
		.catch(function (error) {
		   console.log(error);
		})
	} 
	render(){
		return(
			<>
			
				{ !this.state.isActive &&
					<div className="Home">
				
				 {/* <MetaTags>
            <title>Lalas web</title>
            <meta name="description" content="lalas web description." />
            <meta property="og:title" content="Lalas web" />
            <meta property="og:image" content="path/to/image.jpg" />
          </MetaTags> */}
				<div className="lalas-slider">
				{ this.state.isActive ?
					<div style={{textAlign:"center"}}>
						<img style={{paddingTop:'20%'}} src="/images/loading.gif"  />
					</div>: <SimpleSlider />
				}
					
				</div>
				<div className="product-slider">
					<div className="container">
						<div className="product-tile">
							<h2>latest products</h2>
							<img src="/images/underline-style-2.png" />
						</div>
						<ProductSlider />
					</div>
				</div>
				 <section className="lala-return">
		          <div className="container">
		            <div className="row align-items-center">
		              <div className="col-md-6">
		                <div className="lalas-return-content">
		                	<h4>the lalas return</h4>
			                <p>Lorem Ipsum is simply dummy text of the printing and 
			                typesetting industry. Lorem Ipsum has
			                 been the industry's standard dummy text ever 
			                 since the 1500s,</p>
			                 <Link to="/sub-category" className="btn-link btn-link2">shop now</Link>
		                </div>
		              </div>
		              <div className="col-md-6">
		              <img src="images/lalas-1.jpg" className="img-fluid" alt="lalas-return"/>
		              </div>
		            </div>
		          </div>
		        </section>
		        <section>
		          <div className="shawls-section">
		            <div className="inner-shawls">
		              <div className="left-content-1">
		              <img src="images/001.jpg" className="img-fluid" alt="dsfds" />
		              <h4>new shawls</h4>
		              </div>
		            </div>
		            <div className="inner-shawls">
		            <div className="left-content-2">
		              <img src="images/002.jpg" className="img-fluid" alt="dsfds" />
		             <div>
		             	<h4>new shawls</h4>
		            	<p>Lorem Ipsum is simply dummy text of the..</p>
		             </div>
		              </div>
		            </div>
		            <div className="inner-shawls">
		           	<div className="left-content-3">
		              <img src="images/003.jpg" className="img-fluid" alt="dsfds" />
		             <div>
		             	<h4>new <span>shawls</span></h4>
		            	<p>Lorem Ipsum is simply dummy text of the..</p>
		             </div>
		              </div>
		            </div>
		          </div>
        		</section>
        		<section className="product-section">
        			<div className="container">
						<div className="product-tile">
							<h2> products</h2>
							<img src="images/underline-style-2.png" />
						</div>
						{ this.state.productItem.length>0 ?<Products itemlist={this.state.productItem} />: <img src="/images/6.gif" /> }
					</div>
        		</section>
        		<section className="lalas-video">
                    <div className="left-box">
                        <div>
                            <h3>New Lalas Company Video</h3>
                            <p>Lorem Ipsum is simply dummy text of the printing 
                            and typesetting industry.
                            Lorem Ipsum has been the industry's
                            standard dummy text ever since the 1500s, 
                            </p>
                            <Link to="/sub-category" className="btn-link btn-link2">shop now</Link>
                        </div>
                    </div>
                    <div className="right-box">
                      <Video />
                    </div>
                </section>
        		<section className="testimonial-section">
        			<div className="container">
        				<div className="product-tile">
							<h2> customer love</h2>
							<img src="images/underline-style-2.png" />
							<p>we take pride in our quality and service</p>
						</div>
						<TestimonialSlider />
        			</div>
        		</section>
        		<section className="explore">
				    <div className="container">
				        <div className="inner-explore">
			            	<div>
				            	<h2>explore our workshop</h2>
					            <p>Lorem Ipsum is simply dummy text of the
					            printing and typesetting industry.
					            </p>
					            <Link to="/sub-category" className="btn-link btn-link3" >shop now</Link>
			            	</div>
			          	</div>
			        </div>
			    </section>
			    <section className="lalas-box">
			        <div className="container">
			            <div className="product-tile">
							<h2> blog section</h2>
							<img src="images/underline-style-2.png" />
							<p>New product releases and expert tips and tricks added weekly</p>
						</div>
			            <div className="row">
							
			                <div className="col-md-4 outer-blog-box">
			                	<div className="inner-blog">
							{this.state.blogone!="" ? <Link to={`/blog/${this.state.blogone.slug}`}  className="blog-link">
			                		<div >
			                		<div className="blog-content1">
			                			<img src={IMAGE_URL + this.state.blogone.blogphote} className="img-fluid" alt="lalas instagram" />
			                			<div className="blog-change"></div>
			                		</div>
			                		<div className="blog-content">
										<span>{this.state.blogone.blogdate}</span>
			                			<p className="up-text">{this.state.blogone.blogtitle }</p>
										<div className="down-text" dangerouslySetInnerHTML={{ __html: this.state.blogone.smalldescription.substring(0, 200) + '...'  }} />
			                		</div>
			                	</div>
			                	</Link>:''}	
								</div>
			                </div>
			                <div className="col-md-4 outer-blog-box">
							<div className="inner-blog">
						 {this.state.blogtwo !=""? 	<Link to={`/blog/${this.state.blogtwo.slug}`}  className="blog-link">
			                		<div >
			                		<div className="blog-content1">
			                			<img src={IMAGE_URL + this.state.blogtwo.blogphote} className="img-fluid" alt="lalas instagram" />
			                			<div className="blog-change"></div>
			                		</div>
			                		<div className="blog-content">
										<span>{this.state.blogtwo.blogdate}</span>
			                			<p className="up-text">{this.state.blogtwo.blogtitle}</p>
										<div className="down-text" dangerouslySetInnerHTML={{ __html: this.state.blogtwo.smalldescription.substring(0, 200) + '...' }} />
			                		</div>
			                	</div>
			                	</Link> :''}
							</div>
			                </div>
			                <div className="col-md-4 outer-blog-box">
							<div className="inner-blog">
						{this.state.blogthree!="" ?<Link to={`/blog/${this.state.blogthree.slug}`} className="blog-link">
			                		
			                		<div className="blog-content1">
			                			<img src={IMAGE_URL + this.state.blogthree.blogphote} className="img-fluid" alt="lalas instagram" />
			                			<div className="blog-change"></div>
			                		</div>
			                		<div className="blog-content">
										<span>{this.state.blogthree.blogdate}</span>
			                			<p className="up-text">{this.state.blogthree.blogtitle}</p>
										<div className="down-text" dangerouslySetInnerHTML={{ __html: this.state.blogthree.smalldescription.substring(0, 200) + '...' }} />
			                		</div>
			                	
			                	</Link>:''}	
								</div>
			                </div>
						
					   </div>
			        </div>
			    </section>
			    <section className="lalas-map">
			       	<MapContainer  />
			    </section>
			</div>
				}
			</>
			)
	}
}
export default Home;