import React from 'react';
// import {Link} from 'react-router-dom'
// import { FaFacebookF } from 'react-icons/fa';
import { API_ROOT } from '../../Services/Api-config';
import axios from 'axios';
import $ from 'jquery';

const initialState = {oldpassword:"", newpassword:"", confirmpassword:"", oldpasswordError:"", newpasswordError:"", confirmpasswordError:"", loading:false, successmsg:"", userid:0 }
class ChangePasswordForm extends React.Component {
    constructor(props) {
      super(props);
     this.state = initialState;
     this.handleSubmit = this.handleSubmit.bind(this);
     this.oldtextInput = React.createRef();
     this.newtextInput = React.createRef();
     this.confirmtextInput = React.createRef();
    }
    componentWillMount = () => {
      // this.setState(initialState);
      if (localStorage.getItem("webuser") != undefined) {
        const userID = JSON.parse(localStorage.getItem("webuser"));
        this.setState({ userid: userID.id })
        }
    }

    validate = () => {
      let oldpasswordError = "";
      let newpasswordError = "";
      let confirmpasswordError = "";
      
      if (this.state.confirmpassword=='') {
        confirmpasswordError = "confirm password can not be blank";
        this.confirmtextInput.current.focus();
        $("#confirmpassword").addClass("errorclass");
      }else{
        $("#confirmpassword").removeClass("errorclass");
      }
      if (this.state.newpassword=='') {
         newpasswordError = "new password can not be blank";
         this.newtextInput.current.focus();
         $("#newpassword").addClass("errorclass");
        }else{
          $("#newpassword").removeClass("errorclass");
        }
      if (this.state.oldpassword=='') {
        oldpasswordError = "old password can not be blank";
        this.oldtextInput.current.focus();
        $("#oldpassword").addClass("errorclass");
      }else{
        $("#oldpassword").removeClass("errorclass");
      }
      
    
      if (this.state.confirmpassword!=this.state.newpassword) {
        confirmpasswordError = "confirm password dose not match";
     }
      
  
      if (oldpasswordError || newpasswordError || confirmpasswordError) {
        this.setState({ oldpasswordError, newpasswordError, confirmpasswordError });
        return false;
      }
    
      return true;
      };

      handleSubmit(event) {
        event.preventDefault();
        //   alert()
        this.setState({successmsg:""})
        const isValid = this.validate();
        if (isValid) {
        this.setState(
            { oldpasswordError:"", newpasswordError:"", confirmpasswordError:"" }
           )
           this.setState({loading: true});
        var dataset = {'newpassword': this.state.newpassword, 'conpassword':this.state.confirmpassword, 'oldpassword': this.state.oldpassword, 'userid':this.state.userid}
        axios.post(`${API_ROOT}webcostomerchangepassword`, dataset).then(response => {
                // console.log(response);
                this.setState({loading: false, successmsg:'Form Submit Successfully', oldpassword:"", newpassword:"", confirmpassword:"" });
                this.refs.file.value = ''
                this.load();
             }).catch(error => {
                console.log(error);
             });
        }
        }
  

  render() {
    return (
     <div className="main-registration-container login-form">
        <div className="form-title">
            <h2>Change Password</h2>
            {/* <p>if you have an account with us, Please <span> login </span> </p> */}
        </div>

       <div id="register">
          <form   name="userRegistrationForm" onSubmit={this.handleSubmit} >
          <label>old Password</label>
          <input type="password" id="oldpassword" ref={this.oldtextInput} value={this.state.oldpassword}  onChange={(event) => { this.setState({ oldpassword: event.target.value }) }}   />
          <div className="errorMsg">{this.state.oldpasswordError}</div>
          <label>new Password</label>
          <input type="password" id="newpassword"   ref={this.newtextInput} value={this.state.newpassword}  onChange={(event) => { this.setState({ newpassword: event.target.value }) }}   />
          <div className="errorMsg">{this.state.newpasswordError}</div>
          <label>confirm Password</label>
          <input type="password"  id="confirmpassword" ref={this.confirmtextInput}  value={this.state.confirmpassword}  onChange={(event) => { this.setState({ confirmpassword: event.target.value }) }}   />
          <div className="errorMsg">{this.state.confirmpasswordError}</div>
          <div className="login-btn">
          {this.state.loading==true ? <img scr="/images/6.gif"/> :''}
          {this.state.successmsg}
          <input type="submit" value="Submit" />
          </div>
          </form>
      </div>
  </div>
    

      );
  }


}


export default ChangePasswordForm;