import React, { createRef } from 'react';
import Captcha from "react-numeric-captcha";
import "../../assets/css/captcha.css";
// import {Link} from 'react-router-dom'
// import { FaFacebookF } from 'react-icons/fa';
import { IoMdRefresh } from 'react-icons/io';
import {connect} from "react-redux"
import axios from 'axios';
import { API_ROOT } from '../../Services/Api-config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';


class ShippingForm extends React.Component {
    constructor() {
      super();
      this.state = {
        fields: {},
        errors: {},
        loader:false,
        productid:[],
        userid:0,
        captchaSuccess: false,
         message: ""
      }
      this.captcha = createRef();
      this.handleChange = this.handleChange.bind(this);
      this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
      this.firstnametextInput = React.createRef();
      this.lastnametextInput = React.createRef();
      this.mobiletextInput = React.createRef();
      this.citytextInput = React.createRef();
      this.countrytextInput = React.createRef();
      this.statetextInput = React.createRef();
      this.pincodetextInput = React.createRef();
      this.addresstextInput = React.createRef();


    };

    componentWillMount() {
      if (localStorage.getItem("webuser") != undefined) {
        const userID = JSON.parse(localStorage.getItem("webuser"));
        this.setState({ userid: userID.id })
      }
    }

    componentDidMount(){
      window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
      this.setState({productid:this.props.data.shoppingCart.map(({ productid }) => productid)});
      // console.log(this.props.data.shoppingCart)
      // console.log(localStorage.getItem('webuser'));
    }

    handleChange(e) {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;
      this.setState({
        fields
      });

    }

    submituserRegistrationForm(e) {
      const { captchaSuccess } = this.state;
        e.preventDefault();
        if (this.validateForm()) {
          if (captchaSuccess) {
            let fields = {};
            this.setState({ message: "" });
            fields["firstname"] = "";
            fields["lastname"] = "";
            fields["Address"] = "";
            fields["mobileno"] = "";
            fields["city"] = "";
            fields["state"] = "";
            fields["country"] = "";
            fields["pincode"] = "";
            this.setState({loader:true})
            let useCouponeCodeId = sessionStorage.getItem('useCouponeCodeId')!=undefined?sessionStorage.getItem('useCouponeCodeId'):'';
            var datapost = {'userid':this.state.userid, 'couponecodeid':useCouponeCodeId, 'firstname':this.state.fields.firstname, 'lastname':this.state.fields.lastname, 'address':this.state.fields.Address, 'mobile':this.state.fields.mobileno , "city":this.state.fields.city, "state":this.state.fields.state,"country":this.state.fields.country, "pincode":this.state.fields.pincode, "cartitem": this.state.productid}
            // console.log(datapost)
            axios.post(`${API_ROOT}customersave`, datapost).then(response => {
               console.log(response);
               if(response.data.Result=="success"){
                this.notify();
               }
               this.props.emptyproduct();
             this.setState({fields:fields, loader:false});
            }).catch(error => {
               console.log(error);
            });
          }else {
            this.captcha.current.refresh();
            this.setState({ message: "Wrong captcha! Try again. " });
          }
        }
  
      }

    notify = () => toast.success("your order has been placed successfully !");

    validateForm() {

      let fields = this.state.fields;
      let errors = {};
      let formIsValid = true;

      if (!fields["Address"]) {
        formIsValid = false;
        errors["Address"] = "*Address is Required.";
        this.addresstextInput.current.focus();
        $("#address").addClass("errorclass");
      }else{
        $("#address").removeClass("errorclass");
      }

      if (!fields["pincode"]) {
        formIsValid = false;
        errors["pincode"] = "*Pincode is Required.";
        this.pincodetextInput.current.focus();
        $("#pincode").addClass("errorclass");
      }else{
        $("#pincode").removeClass("errorclass");
      }

      if (!fields["state"]) {
        formIsValid = false;
        errors["state"] = "*State is Required.";
        this.statetextInput.current.focus();
        $("#state").addClass("errorclass");
       
      }else{
        $("#state").removeClass("errorclass");
      }
     
       if (!fields["country"]) {
        formIsValid = false;
        errors["country"] = "*Country is Required.";
        this.countrytextInput.current.focus();
        $("#country").addClass("errorclass");
       
      }else{
        $("#country").removeClass("errorclass");
      }
        
      if (!fields["city"]) {
        formIsValid = false;
        errors["city"] = "*City is Required.";
        this.citytextInput.current.focus();
        $("#city").addClass("errorclass");
        
      }else{
        $("#city").removeClass("errorclass");
      }

      
      if (!fields["mobileno"]) {
        formIsValid = false;
        errors["mobileno"] = "*Mobile Number is Required.";
        this.mobiletextInput.current.focus();
        $("#mobileno").addClass("errorclass");
      
      }else{
        $("#mobileno").removeClass("errorclass");
      }

      
    

      if (typeof fields["mobileno"] !== "undefined") {
        if (!fields["mobileno"].match(/^((?:[+?0?0?968]+)(?:\s?\d{2})(?:\s?\d{8}))$/)) {
          formIsValid = false;
          errors["mobileno"] = "*Please Enter valid Mobile Number.";
          this.mobiletextInput.current.focus();
          $("#mobile").addClass("errorclass");
        
        }
      }

      
      if (!fields["lastname"]) {
        formIsValid = false;
        errors["lastname"] = "*Last Name is Required.";
        window.scrollTo({
          top: 0,
          behavior: "smooth"
      });
        this.lastnametextInput.current.focus();
        $("#lastname").addClass("errorclass");
        
      }else{
        $("#lastname").removeClass("errorclass");
      }


      if (!fields["firstname"]) {
        formIsValid = false;
        errors["firstname"] = "*First Name is Required.";
        this.firstnametextInput.current.focus();
        $("#firstname").addClass("errorclass");
        window.scrollTo({
          top: 0,
          behavior: "smooth"
      });
      }else{
        $("#firstname").removeClass("errorclass");
      }
      this.setState({ errors: errors  });
      return formIsValid;

    }





  render() {
    const { message } = this.state;
    return (
     <div className="main-registration-container login-form1">
        <div className="form-title">
            <h2>shipping address</h2>
            <p>Please enter the following information to create your account.</p>
        </div>

       <div id="register">
          <form method="post"  name="userRegistrationForm"  onSubmit= {this.submituserRegistrationForm} >
          <div className="ship-box">
              <div className="l-ship-box">
                <label>first name</label>
                <input type="text" id="firstname" ref={this.firstnametextInput} name="firstname" value={this.state.fields.firstname} onChange={this.handleChange}  />
                <div className="errorMsg">{this.state.errors.firstname}</div>
              </div>
              <div className="r-ship-box">
                  <label>last name</label>
                  <input type="text" id="lastname" ref={this.lastnametextInput} name="lastname" value={this.state.fields.lastname} onChange={this.handleChange}  />
                  <div className="errorMsg">{this.state.errors.lastname}</div>
              </div>
          </div>
          <div className="ship-box">
              <div className="l-ship-box">
                <label>mobile no</label>
                <input type="number" id="mobileno" ref={this.mobiletextInput} name="mobileno" value={this.state.fields.mobileno} onChange={this.handleChange}  />
                <div className="errorMsg">{this.state.errors.mobileno}</div>
              </div>
              <div className="r-ship-box">
                  <label>city</label>
                  <input type="text" id="city" name="city" ref={this.citytextInput} value={this.state.fields.city} onChange={this.handleChange}  />
                  <div className="errorMsg">{this.state.errors.city}</div>
              </div>
          </div>
          <div className="ship-box">
              <div className="l-ship-box">
                <label>country</label>
                <input type="text" id="country" name="country" ref={this.countrytextInput} value={this.state.fields.country} onChange={this.handleChange}  />
                <div className="errorMsg">{this.state.errors.country}</div>
              </div>
              <div className="r-ship-box">
                  <label>state</label>
                  <input type="text" id="state" name="state" ref={this.statetextInput} value={this.state.fields.state} onChange={this.handleChange}  />
                  <div className="errorMsg">{this.state.errors.state}</div>
              </div>
          </div>
          <div className="ship-box">
              <div className="l-ship-box">
                <label>Pin Code</label>
                <input type="number" id="pincode" name="pincode" ref={this.pincodetextInput} value={this.state.fields.pincode} onChange={this.handleChange}  />
                <div className="errorMsg">{this.state.errors.pincode}</div>
              </div>
           
          </div>
          <div className="address-box">
              <label>Address</label>
            <textarea  id="address" type="text" name="Address" ref={this.addresstextInput} value={this.state.fields.Address} onChange={this.handleChange} ></textarea>
            <div className="errorMsg">{this.state.errors.Address}</div>
          </div>
          
          <div className=" address-box">
              <label>captcha code</label>
              <div className="cap-box">
              <Captcha
                ref={this.captcha}
                onChange={status => this.setState({ captchaSuccess: status })}
              />
              <br /> 
                {/* <input type="text" name="captcha" id="cap-it"/>
                <input type="text" name="captcha" id="cap-its" placeholder="HvGdv"/>
                <p><IoMdRefresh /></p> */}
              </div>
               {this.state.message !=''?<div className="errorMsg">{message}</div>:''} 
          </div>
          <div className="">
            {this.state.loader==true ? <img src="/images/6.gif" /> :''}
          </div>
          <div className="login-btn address-box">
            <button>place order</button>
            
          </div>
          </form>
          <ToastContainer position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover />
      </div>
  </div>
    

      );
  }


}


const mapsStateToProps=(state)=>{
  return {
    data: state
  }
}

const mapDispatchToProps = (dispatch)=> {
  return {
    emptyproduct: () =>
      dispatch({
        type: "EMPTY"
      })
  };
}


export default connect(mapsStateToProps, mapDispatchToProps)(ShippingForm);