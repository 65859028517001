import React from 'react'
import { Link } from 'react-router-dom'
import Products from './../common/product1'
import { MdKeyboardArrowRight } from "react-icons/md";
import $ from 'jquery'
import axios from 'axios';
import { API_ROOT, IMAGE_URL } from '../../Services/Api-config';

const initialState = { productItem: [], categorylist: [], categorychild: [], parentcategoryname: "", showsubcategoryname: "", activeclass: false, slug:'', divid: 0, activecategoryid:0, loader:false }
class Product extends React.Component {
	constructor(props) {
		super(props);
		this.state = initialState;
	}

	componentDidUpdate = (prevProps) => {
		if (this.props.match.params.slug !== prevProps.match.params.slug) {
			this.activenavigationclass()
			this.setState({slug:this.props.match.params.slug})
			this.ProductViewSub(this.props.match.params.slug);
			if(this.state.categorylist.length>0){
				var filtersubcategoryname = this.state.categorylist.filter(data =>{ return data.slug==this.props.match.params.slug});
				var filterparentcategoryname = this.state.categorylist.filter(data =>{ return data.slug==this.props.match.params.parentcty});
				this.setState({showsubcategoryname:filtersubcategoryname[0].name, parentcategoryname: filterparentcategoryname[0].name });
			}
		}
		// console.log(this.state.categorylist)
		
		
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	}
 	componentDidMount () {
		
	 	 this.navcategory();
		const slug = this.props.match.params.slug;
		if (this.props.match.params.slug != undefined) {
			this.setState({slug:this.props.match.params.slug})
			this.ProductViewSub(slug);
		} else {
			this.load();
		}
		
		// , showsubcategoryname: response.data.success[0].subcategoryname
		this.jqueryCall();
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	}


	ProductViewSub(slug) {
		this.setState({loader:true})
		axios.get(`${API_ROOT}getproductsub/` + slug)
			.then(response => {
				this.setState({loader:false})
				// console.log(response.data.success);
				if (response.data.success.length > 0) {
					this.setState({ productItem: response.data.success });
				} else {
					this.setState({ productItem: response.data.success })
				}

			})
			.catch(function (error) {
				console.log(error);
			})
	}

	jqueryCall() {
		// $(".category-list").click(function(){
		// 	$(this).toggleClass("inner-category-list");				
		//   }); 

		$(document).ready(function () {
			$(window).scroll(function () {
				if ($(document).scrollTop() > 150) {
					$(".Cate").addClass("test");
				} else {
					$(".Cate").removeClass("test");
				}
			});
		});
		$(document).ready(function () {
			$(window).scroll(function () {
				if ($(document).scrollTop() > 400) {
					$(".Cate").removeClass("test");
				}
				else {

				}
			});
		});

	}

	load() {
		axios.get(`${API_ROOT}customerwebproduct`)
			.then(response => {
				// console.log(response.data.success);
				this.setState({ productItem: response.data.success });

			})
			.catch(function (error) {
				console.log(error);
			})

	}

	navcategory() {
		//  cateory
		axios.get(`${API_ROOT}customercategory`)
			.then(response => {
				// console.log(response.data.success);
				var filtersubcategoryname = response.data.success.filter(data =>{ return data.slug==this.props.match.params.slug});
				var filterparentcategoryname = response.data.success.filter(data =>{ return data.slug==this.props.match.params.parentcty});
		console.log(filtersubcategoryname);
				this.setState({ categorylist: response.data.success, showsubcategoryname:filtersubcategoryname[0].name, parentcategoryname: filterparentcategoryname[0].name });
				this.activenavigationclass()
			})
			.catch(function (error) {
				console.log(error);
			})
	}

	activenavigationclass(){
		for(var i=0; i<this.state.categorylist.length; i++){
			if(this.props.match.params.slug ==this.state.categorylist[i].slug ){
				this.setState({activecategoryid: this.state.categorylist[i].parent_id, divid:0})
			}

		}
	}

	onClickview = event => {
		axios.get(`${API_ROOT}getproductprice/` + event.target.value + '/'+this.props.match.params.slug)
			.then(response => {
				// console.log(response.data.success);
				this.setState({ productItem: response.data.success });
			})
			.catch(function (error) {
				console.log(error);
			})
	}


	changeulli(e, i) {
		this.state.activeclass = !this.state.activeclass;
		this.setState({ divid: e, activecategoryid:0 })
	}

	render() {
		return (
			<div className="Product">
				<section class="contact-img-area">
					<div class="container">
						<div class="row">
							<div class="col-md-12 text-center">
								<div class="con-text">
									<h2 class="page-title">Categoires</h2>
									<p><Link to={`/`}>Home </Link> <i class="fa fa-angle-right"></i> <Link to={`/sub-category/${this.props.match.params.parentcty}`}>{this.state.parentcategoryname} </Link>  <i class="fa fa-angle-right"></i> {this.state.showsubcategoryname}</p>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section class="design-area home-five" >
					<div class="container">
						<div className="row">
							<div className="col-md-3">
								<div className="Cate">
									<div className="inner-cate">
										<h2>Categoires</h2>

										<ul>

											{this.state.categorylist.map((object, i) => {
												return (
													<>
														{object.parent_id == 0 && (
															<li key={i + 1}>
																<div className={`category-list ${ this.state.divid == i + 1  || (this.state.activecategoryid!=0 && this.state.activecategoryid== object.id)  ? 'inner-category-list' : ""}`} onClick={this.changeulli.bind(this, i + 1)}>
																	<div className="cat-content">
																	{ this.state.activecategoryid== object.id  ? <Link  ><b>{object.name} </b> </Link>: <Link  >{object.name} </Link> }
																		
																		<span><MdKeyboardArrowRight /></span></div>
																	<ul className="innerlist-content">
																		{this.state.categorylist.map((item, j) => {
																			return (
																				<>
																					{item.parent_id == object.id && (
																						<li>
																							{
																							this.state.slug==item.slug?<Link  style={{color:'#e85e00'}} to={`/product/${object.slug}/${item.slug}`}>
																							{item.name}</Link>
																							:
																							<Link to={`/product/${object.slug}/${item.slug}`}>
																							{item.name}</Link>
																							}	
																							
																						</li>
																					)}
																				</>

																			)
																		})
																		}
																	</ul>
																</div>
															</li>

														)}
													</>
												)
											})
											}


										</ul>
									</div>
								</div>
							</div>
							<div className="col-md-9">
								<div class="product-content">
									<div className="inner-product-content">
										<span className="s-title">  {this.state.showsubcategoryname!=''?<>{this.state.showsubcategoryname}</>:''}</span>
										<span className="g-title">
											<label>sort by :</label>
											<select className="sorted" onChange={this.onClickview}  >
												{/* <option className="opt" value={1}>default storing</option>
				  							<option className="opt" value={2}>sort as popularity</option>
				  							<option className="opt" value={3}>sort by average time </option>
				  							<option className="opt" value={4}>sort by news</option> */}
												<option className="opt" value={5}>High to low price</option>
												<option className="opt" value={6}>low to High price</option>
											</select>
										</span>
									</div>
									
									{this.state.loader==true ? <img src="/images/6.gif" /> :
									<>
										{this.state.productItem.length > 0 ? <Products itemlist={this.state.productItem} subcategory={this.props.match.params.slug} parentcategory={this.props.match.params.parentcty} /> 
										: 
										// <img src="/images/product-coming-soon.jpg" />
										<h2>New Products Coming Soon!</h2>
										}
									</>
									}

								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		)
	}
}
export default Product;