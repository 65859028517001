import React, { createRef } from 'react';
import {Link} from 'react-router-dom'
import Captcha from "react-numeric-captcha";
import "../../assets/css/captcha.css";
// import { FaFacebookF } from 'react-icons/fa';
import { IoMdRefresh } from 'react-icons/io';
import axios from 'axios';
import { API_ROOT } from '../../Services/Api-config';
import queryString from 'query-string';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const initialstate = {input: {}, page:null, errors: {},successmsg:"", captchaSuccess: false, message: ""}

class RegisterForm extends React.Component {
  constructor() {
    super();
     this.state = initialstate;
     this.captcha = createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.firstnametextInput = React.createRef();
    this.lastnametextInput = React.createRef();
    this.phonetextInput = React.createRef();
    this.emailtextInput = React.createRef();
    this.passwordtextInput = React.createRef();
    this.reenterpasswordtextInput = React.createRef();
    this.captchatextInput = React.createRef();
  }

  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
  });
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('page');
    console.log(myParam)
  this.setState({page : queryString.parse(this.props.location).page});
  }
     
  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
  
    this.setState({
      input
    });
  }
     
  handleSubmit(event) {
    const { captchaSuccess } = this.state;
    event.preventDefault();
  
    if(this.validate()){

      if (captchaSuccess) {
        this.setState({ message: "" });
       const formData = new FormData();
           const customHeaders = {
           'content-type': 'application/json',
        };
        axios.post(`${API_ROOT}customerregister`, { name: this.state.input.firstname, last_name: this.state.input.lastname,email: this.state.input.email ,password: this.state.input.password,c_password:this.state.input.confirm_password,phone:this.state.input.phone, role:'user' }, customHeaders).then(response => {
            console.log(response);
            if(response.data.error!=undefined){
              this.notify();
            }
            if(response.data.success)
            {
                let input = {};
                input["firstname"] = "";
                input["lastname"] = "";
                input["phone"] = "";
                input["email"] = "";
                input["password"] = "";
                input["confirm_password"] = "";
                this.setState({input:input});
              this.setState({successmsg:'You Are Registered Successfully!'})
           }
           }).catch(error => {
            console.log(error);
         });
    }else {
      this.captcha.current.refresh();
      this.setState({ message: "Wrong captcha! Try again. " });
    }
  }
  }
  validate(){
      let input = this.state.input;
      let errors = {};
      let isValid = true;
  
      if (!input["confirm_password"]) {
        isValid = false;
        errors["confirm_password"] = "Confirm Password is Required.";
        this.reenterpasswordtextInput.current.focus();
        $("#confirm_password").addClass("errorclass");
      }else{
        $("#confirm_password").removeClass("errorclass");
      }

      if (!input["password"]) {
        isValid = false;
        errors["password"] = "Password is Required.";
        this.passwordtextInput.current.focus();
        $("#password").addClass("errorclass");
      }else{
        $("#password").removeClass("errorclass");
      }

      if (typeof input["email"] !== "undefined") {
          
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(input["email"])) {
          isValid = false;
          errors["email"] = "Please Enter Valid Email Address.";
          this.emailtextInput.current.focus();
          $("#email").addClass("errorclass");
        }else{
          $("#email").removeClass("errorclass");
        }
      }

      if (!input["email"]) {
        isValid = false;
        errors["email"] = "Email Address is Required.";
        this.emailtextInput.current.focus();
        $("#email").addClass("errorclass");
        window.scrollTo({
          top: 150,
          behavior: "smooth"
      });
      }else{
        $("#email").removeClass("errorclass");
      }

      if (!input["phone"]) {
        isValid = false;
        errors["phone"] = "Phone Number is Required.";
        this.phonetextInput.current.focus();
        $("#phone").addClass("errorclass");
        window.scrollTo({
          top: 100,
          behavior: "smooth"
      });
      }else{
        $("#phone").removeClass("errorclass");
      }

      if (!input["lastname"]) {
        isValid = false;
        errors["lastname"] = "Last Name is Required.";
        this.lastnametextInput.current.focus();
        $("#lastname").addClass("errorclass");
      }else{
        $("#lastname").removeClass("errorclass");
      }

      if (!input["firstname"]) {
        isValid = false;
        errors["firstname"] = "First Name is Required.";
        this.firstnametextInput.current.focus();
        $("#firstname").addClass("errorclass");
        window.scrollTo({
          top: 0,
          behavior: "smooth"
      });
      }else{
        $("#firstname").removeClass("errorclass");
      }
  
      if (typeof input["password"] !== "undefined" && typeof input["confirm_password"] !== "undefined") {
          
        if (input["password"] != input["confirm_password"]) {
          isValid = false;
          errors["password"] = "Passwords don't match.";
        }
      } 
      
  
      this.setState({
        errors: errors
      });
  
      return isValid;
  }
    



  notify = () => toast.error("The email has already been taken.");

  render() {
    const { message } = this.state;
    return (
     <div className="main-registration-container login-form">
        <div className="form-title">
            <h2>create a new account</h2>
            <p>Please enter the following information to <span> create your account. </span> </p>
        </div>

       <div id="register">
          <form  onSubmit={this.handleSubmit}>
          <label>first name</label>
         <input 
              type="text" 
              name="firstname" 
              class="form-control" 
              value={this.state.input.firstname}
              onChange={this.handleChange} 
              id="firstname" ref={this.firstnametextInput} />
          <div className="errorMsg">{this.state.errors.firstname}</div>
          <label>last name</label>
                 <input 
                type="text" 
                name="lastname" 
                class="form-control" 
                value={this.state.input.lastname}
                onChange={this.handleChange}
                id="lastname" ref={this.lastnametextInput} />
           <div className="errorMsg">{this.state.errors.lastname}</div>
          <label>Phone</label>
          <input 
              type="number" 
              name="phone" 
              class="form-control" 
              title="Please enter valid phone number ex-9999999999"
              value={this.state.input.phone}
              onChange={this.handleChange} 
              id="phone" ref={this.phonetextInput} />
          <div className="errorMsg">{this.state.errors.phone}</div>
          <label>Email</label>
          <input 
              type="email" 
              name="email" 
              value={this.state.input.email}
              onChange={this.handleChange}
              class="form-control" 
              id="email" ref={this.emailtextInput} />
          <div className="errorMsg">{this.state.errors.email}</div>
          <label>Password</label>
         
            <input 
              type="password" 
              name="password"
              class="form-control"  
              value={this.state.input.password}
              onChange={this.handleChange}
              id="password" ref={this.passwordtextInput} />
          <div className="errorMsg">{this.state.errors.password}</div>
          <label>Re-enter Password</label>
           <input 
              type="password" 
              name="confirm_password" 
              class="form-control" 
              value={this.state.input.confirm_password}
              onChange={this.handleChange}
              id="confirm_password"
              ref={this.reenterpasswordtextInput}  />
           <div className="errorMsg">{this.state.errors.confirm_password}</div>
          <label>captcha code</label>
          <div className="cap-box">
          <Captcha
            ref={this.captcha}
            onChange={status => this.setState({ captchaSuccess: status })}
          />
          <br /> 
              {/* <input type="text" name="captcha" id="cap-it"/>
          <input type="text" name="captcha" id="cap-its" placeholder="HvGdv"/> */}
          </div>
          {this.state.message !=''?<div className="errorMsg">{message}</div>:''} 
             {this.state.successmsg != '' ? <p style={{ color: 'green' }}>{this.state.successmsg}</p> : ''}
          <div className="login-btn login-btn2">
            <p><IoMdRefresh /></p>
            <button >sign up</button>
            {this.state.page!=null ? <Link to="/login" className="home-log-btn"> login </Link> : <Link to="/login?page=cart" className="home-log-btn"> login </Link> }
              
            {/* </div> */}
          </div>
          </form>
          <ToastContainer position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover />
      </div>
  </div>
    

      );
  }


}


export default RegisterForm;