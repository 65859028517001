import React from 'react'
import {Link} from 'react-router-dom'
import { API_ROOT, IMAGE_URL } from '../../Services/Api-config';
import axios from 'axios';

const initialState = { image:"" }
class Dashboard extends React.Component{

	constructor(props) {
		super(props);
		this.state = initialState;
	}

	componentWillMount() {
		if (localStorage.getItem("webuser") != undefined) {
			const userID = JSON.parse(localStorage.getItem("webuser"));
			this.setState({ image: userID.image })
		}
	}	


	render(){
		return(
				<div>
					<div className="profile-details-box">
						<div className="left-details-box">
							{/* <img src="/images/p003.jpg" /> */}
							{/* {this.state.image !=null?<img src={IMAGE_URL+this.state.image} />:<img src="images/p003.jpg" />} */}
						</div>
						<div className="right-details-box">
							<h3>welcome to dashboard</h3>
						</div>
					</div>
				</div>
			)
	}
}
export default Dashboard;