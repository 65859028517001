import React from 'react'
import {Link} from 'react-router-dom'
import { MdLocationOn } from 'react-icons/md';
import { FaPhoneAlt } from 'react-icons/fa';
import { MdPhoneAndroid } from 'react-icons/md';
import { MdPhonelink } from 'react-icons/md';
import { FaEnvelope } from 'react-icons/fa';
import { FaFacebookF } from 'react-icons/fa';
import { AiOutlineTwitter } from 'react-icons/ai';
import { FaLinkedinIn } from 'react-icons/fa';
import { IoIosWifi } from 'react-icons/io';
import { MdWifiTethering } from 'react-icons/md';
import { API_ROOT } from '../../Services/Api-config';
import axios from 'axios';

const initialState = { categorylist: [] };
class Footer extends React.Component{

	constructor(props) {
		super(props);
		this.state = initialState;
	}

	componentDidMount = () => {
		this.load();
	}

	load() {
		axios.get(`${API_ROOT}customercategory`)
			.then(response => {
				this.setState({ categorylist: response.data.success });
			})
			.catch(function (error) {
				console.log(error);
			})

	}

	render(){
		return(
			<div className="Footer">
			<footer className="footer-area">
			  <div className="footer-top">
			    <div className="container">
			      <div className="row">
				      <div className="col-md-12 footer-column">
				       	<div className="inner-footer">
				       		 <div className="footy">
				          <div className="first-footer rspn">
				            <h3 className="wg-title">Contact Us</h3>
				            <div className="textwidget">
				              <ul>
				                <div className="company">Rana Abdul Rahim & Partners L.L.C.
				                  <p>Lalas Group of Companies</p>
				                </div>
				                <li className="address-foot"> <span className="footer-first-icon"><MdLocationOn /></span>
				                  <p>P.O. Box 1031, Postal Code 114 Muscat, Sultanate of Oman</p>
				                </li>
				                <li> <span className="footer-first-icon"><FaPhoneAlt /></span><span className="footer-no"> +968 24562576 </span> </li>
				                <li> <span className="footer-first-icon"><MdPhoneAndroid /></span><span className="footer-no"> +968 24562678 </span> </li>
				                <li> <span className="footer-first-icon"><MdPhonelink /></span> <span className="footer-no"> +968 24562551 </span> </li>
				                <li> <span className="footer-first-icon"><FaEnvelope /></span><span className="footer-no"><Link to="mailto:info@lalasweb.com"> info@lalasweb.com</Link></span> </li>
				              </ul>
				            </div>
				          </div>
				        </div>
				        <div className="footy">
				          <div className="first-footer rspn">
				            <h3 className="wg-title">Information</h3>
				            <div className="textwidget">
				              <ul className="f-none">
				                <li><Link to="/">Home</Link></li>
				                <li><Link to="/about">About Us</Link></li>
				                <li><Link to="/contact">Contact us</Link></li>
				              </ul>
				            </div>
				          </div>
				        </div>
				        <div className="footy">
				          <div className="first-footer rspn">
				            <h3 className="wg-title">Our Products</h3>
				            <div className="textwidget">
				              <ul className="f-none">
							  {this.state.categorylist.map((object, i) => {
														return (
															<div key={i}>
															{object.parent_id == 0 && (
																<li key={i}><Link to={`/sub-category/${object.slug}`}>
																{object.name}</Link></li>
																)}
																</div>
														)
									})
								}
				              </ul>
				            </div>
				          </div>
				        </div>
				        <div className="footy">
				          <div className="first-footer res-mrg-top-md rspn">
				            <h3 className="wg-title">Our Policy</h3>
				            <div className="textwidget">
				              <ul className="f-none">
								{
    (!localStorage.getItem('webuser'))  ? 
<li className="icon-list"><Link to="/profile" >My Account</Link></li>
 : <li className="icon-list"><Link to="/profile"  >My Account</Link></li>
}
								{
    (!localStorage.getItem('webuser'))  ? 
<li className="icon-list"><Link to="/login" >Order Histor</Link></li>
 : <li className="icon-list"><Link to="/profile"  >Order Histor</Link></li>
}

				              </ul>
				            </div>
				          </div>
				        </div>
				        <div className="footy">
				          <div className="last-footer res-mrg-top-md">
				             <div className="widget_text">
				              <h3 className="wg-title">Connect Us</h3>
				              <div className="textwid">
				                <ul className="socials">
				                  <li><Link to="#"><span><FaFacebookF /></span></Link></li>
				                  <li><Link to="#"><span><AiOutlineTwitter  /></span></Link></li>
				                  <li><Link to="#"><span><FaLinkedinIn /></span></Link></li>
				                  <li><Link to="#"><span><IoIosWifi /></span></Link></li>
				                  <li><Link to="#"><span><MdWifiTethering /></span></Link></li> 
				                </ul>
				              </div>
				            </div>
				          </div>
				        </div>
				       	</div>
			        </div>
			      </div>
			    </div>
			  </div>
			  <div className="footer-bottom">
			    <div className="container">
			      <div className="row">
			        <div className="col-lg-4 col-md-4 col-12">
			          <div className="footer-address">
			            <address>
			            Copyright © <Link to="#">lalasweb.com </Link> | All Rights Reserved
			            </address>
			          </div>
			        </div>
			        <div className="col-lg-4 col-md-4 col-12">
			          <div className="foot-icon">
			            <ul>
			              <li><Link to="#"><img src="/images/icon-img/payment-1.jpg" alt="" /></Link></li>
			              <li><Link to="#"><img src="/images/icon-img/payment-2.jpg" alt="" /></Link></li>
			              <li><Link to="#"><img src="/images/icon-img/payment-3.jpg" alt="" /></Link></li>
			              <li><Link to="#"><img src="/images/icon-img/payment-4.jpg" alt="" /></Link></li>
			            </ul>
			          </div>
			        </div>
			        <div className="col-lg-4 col-md-4 col-12">
			          <div className="foot-icon">
			            <ul>
			              <li><a href="http://akswebsoft.com/" target="_blank"><img src="/images/powerdby.png" alt="" /></a></li>
			            </ul>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</footer>
			</div>

			)
	}
}
export default Footer;