import React from "react";
import Slider from "react-slick";
import {Link} from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';
import { API_ROOT, IMAGE_URL } from '../../Services/Api-config';
 
const initialState = {testimoniallist:[]}
class TestimonialSlider extends React.Component {

  constructor(props) {
		super(props);
		this.state = initialState;
	}
	componentDidMount = () => {
		axios.get(`${API_ROOT}webcustomerlove`)
		.then(response => {
				console.log(response.data.success);
				   this.setState({ testimoniallist: response.data.success });
			
		})
		.catch(function (error) {
		   console.log(error);
		})
		
	} 

  render() {
    var settings = {
      dots: true,
      infinite: true,
      autoplay:true,
      autoplaySpeed: 3000,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <Slider {...settings}>
        
        {this.state.testimoniallist.length >0 && this.state.testimoniallist.map((object, i) =>
        <div className="Proslider1">
          <div>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                    <div className="left-test-box">
                      <div className="inner-testimonial">
                        <img src="images/coma.png" />
                        <span dangerouslySetInnerHTML={{ __html: object.description }}></span>
                        
										
                        
                      </div>
                      <div className="test-content">
                        <img src={IMAGE_URL + object.image} />
                        <span>{object.testimonial}</span>
                        <p>{object.post}</p>
                      </div>
                    </div>
                </div>
              
              </div>
            </div>
          </div>
        </div>
       )}
       </Slider>
    );
  }
}
export default TestimonialSlider;