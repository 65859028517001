import React from 'react';
import {Link} from 'react-router-dom'
import axios from 'axios';
import { API_ROOT } from '../../Services/Api-config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class ForgetForm extends React.Component {
    constructor() {
      super();
      this.state = {
        fields: {},
        errors: {}
      }

      this.handleChange = this.handleChange.bind(this);
      this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);
      this.usernametextInput = React.createRef();

    };

    handleChange(e) {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;
      this.setState({
        fields
      });

    }

  async  submituserRegistrationForm(e) {
      e.preventDefault();
      if (this.validateForm()) {
        this.notify();
          const customHeaders = {
            'content-type': 'application/json',
          };
          await axios.post(`${API_ROOT}forget-password`, { email: this.state.fields.emailid}, customHeaders).then(response => {
            console.log(response)
            if (response.data.success=='success') {
              // this.usernametextInput.current.value("");
            } 
          }).catch(error => {
            console.log(error);
          });
          // alert("Form submitted");
      }

    }

    validateForm() {

      let fields = this.state.fields;
      let errors = {};
      let formIsValid = true;

     

      

      if (!fields["emailid"]) {
        formIsValid = false;
        errors["emailid"] = "*Please enter your email-ID.";
        this.usernametextInput.current.focus();
      }

      if (typeof fields["emailid"] !== "undefined") {
        //regular expression for email validation
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(fields["emailid"])) {
          formIsValid = false;
          errors["emailid"] = "*Please enter valid email-ID.";
          this.usernametextInput.current.focus();
        }
      }

      

      

      this.setState({
        errors: errors
      });
      return formIsValid;


    }

    notify = () => toast.success("Password has been sent to your registered email id");

  render() {
    return (
     <div className="main-registration-container login-form">
        <div className="form-title">
            <h2>Forget</h2>
            <p>if you have an account with us, Please <span>  <Link to="/login">login</Link></span> </p>
        </div>

       <div id="register">
          <form method="post"  name="userRegistrationForm"  onSubmit= {this.submituserRegistrationForm} >
          <label>Email address</label>
          <input type="email" class="form-control" ref={this.usernametextInput}  name="emailid" defaultValue={this.state.fields.emailid} onChange={this.handleChange} />
          <div className="errorMsg">{this.state.errors.emailid}</div>
          <div className="login-btn">
            <button>login</button>
            
          </div>
         
          </form>
          <ToastContainer position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover />
      </div>
  </div>
    

      );
  }


}


export default ForgetForm;