import React from 'react'

class Error extends React.Component{
    render(){
        return(
            <div className="About">
                <div className="error">
                    <h3>Error 404 !</h3>
                </div>
            </div>
        )
    }
}
export default Error;