// return the user data from the session storage
export const getUser = () => {
  const userStr = localStorage.getItem('webuser');
  if (userStr) return JSON.parse(userStr);
  else return null;
}

// return the token from the session storage
export const getToken = () => {
  return localStorage.getItem('webtoken') || null;
}

// remove the token and user from the session storage
export const removeUserSession = () => {
  localStorage.removeItem('webtoken');
  localStorage.removeItem('webuser');
  
  sessionStorage.removeItem('useCouponeCode')
  sessionStorage.removeItem('useCouponeCodeId')
  sessionStorage.removeItem('useCouponType')
  sessionStorage.removeItem('useCouponValue')
}

// set the token and user from the session storage
export const setUserSession = (token, user) => {
  localStorage.setItem('webtoken', token);
  localStorage.setItem('webuser', JSON.stringify(user));
}