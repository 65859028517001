let backendHost;
let imageurl;

const hostname = window && window.location && window.location.hostname;

if (hostname.indexOf("localhost") > -1) {
  backendHost = "http://localhost/blog/api/";
  imageurl    = 'http://localhost/blog/public/';
} else {  
  backendHost = "http://lalasweb.com/apiAdmin/api/";
  imageurl    = 'http://lalasweb.com/apiAdmin/public/';
  // backendHost = "http://lalas.akswebsoft.co/apiAdmin/api/";
  // imageurl    = 'http://lalas.akswebsoft.co/apiAdmin/public/';
}

export const API_ROOT = `${backendHost}`;

export const IMAGE_URL = `${imageurl}`;