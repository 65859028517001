import React from 'react';
import { Link } from 'react-router-dom'
import { FaFacebookF } from 'react-icons/fa';
import axios from 'axios';
import { withRouter } from 'react-router'
import { setUserSession } from '../../Utils/Common';
import { API_ROOT } from '../../Services/Api-config';
import { connect } from "react-redux"
import queryString from 'query-string';
import $ from 'jquery';


class LoginForm extends React.Component {
  constructor() {
    super();
    this.state = {
      input: {},
      page:'',
      errors: {},
      successmsg: "",
      loginerror: "",
      shoppingCart: [],
      totalPrice: 0,
      qty: 0
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.usernametextInput = React.createRef();
      this.passwordtextInput = React.createRef();
  }

  componentDidMount() {
    // console.log(queryString.parse(this.props.location.search).page);
    this.setState({page : queryString.parse(this.props.location.search).page});
    this.setState({ shoppingCart: this.props.data.shoppingCart, totalPrice: this.props.data.totalPrice, qty: this.props.data.qty })
    this.setState({ loginerror: "" })
  }


  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;

    this.setState({
      input
    });
  }

  async handleSubmit(event) {
    event.preventDefault();

    if (this.validate()) {
      const customHeaders = {
        'content-type': 'application/json',
      };
      await axios.post(`${API_ROOT}customerlogin`, { email: this.state.input.email, password: this.state.input.password }, customHeaders).then(response => {
        if (response.data.error) {
          this.setState({ loginerror: "Login Credentials does not match" })
        } else {
          if (response.data.success.cartproduct.products.length > 0) {
            const settolocalproduct = [...response.data.success.cartproduct.products]
            for (var i = 0; i < settolocalproduct.length; i++) {
              this.props.addtocartproduct(settolocalproduct, settolocalproduct[i], settolocalproduct[i].productid, settolocalproduct[i].qty)
            }
          }
          this.setState({ loginerror: "" })
          setUserSession(response.data.success.token, response.data.success.user);
          this.state.page!=undefined? this.props.history.push('/cart') :this.props.history.goBack();
          
        }
      }).catch(error => {
        console.log(error);
      });
    }
  }

  validate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;

    if (!input["password"]) {
      isValid = false;
      errors["password"] = "Password is Required.";
      this.passwordtextInput.current.focus();
      $("#passwordinput").addClass("errorclass");
    }else{
      $("#passwordinput").removeClass("errorclass");
    }

    if (!input["email"]) {
      isValid = false;
      errors["email"] = "Username is Required.";
      this.usernametextInput.current.focus();
      $("#emailinput").addClass("errorclass");
    }else{
      $("#emailinput").removeClass("errorclass");
    }

    if (typeof input["email"] !== "undefined") {

      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(input["email"])) {
        isValid = false;
        errors["email"] = "Please enter valid email address.";
        this.usernametextInput.current.focus();
        $("#emailinput").addClass("errorclass");
      }
    }

   

    this.setState({
      errors: errors
    });

    return isValid;
  }


  render() {
    return (
      <div className="main-registration-container login-form">
        <div className="form-title">
          <h2>login</h2>
          <p>if you have an account with us, Please <span> login </span> </p>
        </div>

        <div id="register">
          <form onSubmit={this.handleSubmit}>
            <label>Email address</label>

            <input
              type="email"
              name="email"
              class="form-control" 
              value={this.state.input.email}
              onChange={this.handleChange}
              id="emailinput"
              ref={this.usernametextInput}
               />
            <div className="errorMsg">{this.state.errors.email}</div>
            <label>Password</label>

            <input
              type="password"
              name="password"
              class="form-control" 
              value={this.state.input.password}
              onChange={this.handleChange}
              id="passwordinput"
              ref={this.passwordtextInput}
               />

            <div className="errorMsg">{this.state.errors.password}</div>
            {this.state.successmsg != '' ? <p style={{ color: 'green' }}>{this.state.successmsg}</p> : ''}
            {this.state.loginerror != '' ? <p style={{ color: 'red' }}>{this.state.loginerror}</p> : ''}
            <div className="login-btn">
              <button>login</button>
              <Link to="/forget">forgot your password ?</Link>
            </div>
            <div className="facebook-box">
              <a href="javascript:void(0)"><FaFacebookF /> sign in with facebook</a>
              {/* <Link to="/login-form"><FaFacebookF /> sign in with facebook</Link> */}
            </div>
            <div className="sign-in-box">
              {this.state.page!=undefined ?<p>New customer ? <Link to="/register?page=cart"> create an account </Link></p> : <p>New customer ? <Link to="/register"> create an account </Link></p>}
              
            </div>
          </form>
        </div>
      </div>


    );
  }


}

const mapsStateToProps = (state) => {
  return {
    data: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addtocartproduct: (settolocalproduct, product, productid, qty) =>
      dispatch({
        type: "ADD_TO_CART_LOGIN",
        allproducts: settolocalproduct,
        products: product,
        productid: productid,
        qty: qty
      }),
    usersession: (userdetail) =>
      dispatch({
        type: "LOGIN",
        userdetail: userdetail
      }),

  };
}



export default connect(mapsStateToProps, mapDispatchToProps)(withRouter(LoginForm));