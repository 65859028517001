import React from 'react'
import { Link } from 'react-router-dom'
import { MdKeyboardArrowRight } from "react-icons/md";
// import { Accordion, AccordionItem } from 'react-light-accordion';
import 'react-light-accordion/demo/css/index.css';
import $ from 'jquery'
import axios from 'axios';
import { API_ROOT, IMAGE_URL } from '../../Services/Api-config';

const initialState = { categoryList: [], categorylistnav: [], activeclass:false, slug:'', divid:0, loader:false, SecretGardenCategory:''}
class SubCategory extends React.Component {
	constructor(props) {
		super(props);
		this.state = initialState;
	}

	componentDidUpdate = (prevProps) => {
		if (this.props.match.params.slug !== prevProps.match.params.slug) {
			if (this.props.match.params.slug === undefined) {
				this.setState({slug:''})
				this.getallsubcategory();
			} else {
				var changertext = this.props.match.params.slug.replace("-", " ");
				this.setState({slug:this.props.match.params.slug, divid:0, SecretGardenCategory:changertext})
				this.getcategorybyslug(this.props.match.params.slug);
				
			}
		}
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	}


	async getcategorybyslug(slug) {
		this.setState({loader:true})
	 await	axios.get(`${API_ROOT}customersubcategoryslug/` + slug).then(response => {
			console.log(response);
			this.setState({ categoryList: response.data.success, loader:false });
		}).catch(error => {
			console.log(error);
		});
	}

	componentDidMount = () => {
		this.jqueryCall();
		if (this.props.match.params.slug != undefined) {
			var changertext = this.props.match.params.slug.replace("-", " ");
			this.setState({SecretGardenCategory:changertext })
			this.setState({slug:this.props.match.params.slug})
			this.getcategorybyslug(this.props.match.params.slug);
		} else {
			this.getallsubcategory();
		}
		this.navigationcategory();
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	}

	getallsubcategory() {
		axios.get(`${API_ROOT}customersubcategory`).then(response => {
			console.log(response);
			this.setState({ categoryList: response.data.success , SecretGardenCategory:''});
		}).catch(error => {
			console.log(error);
		});

	}

	navigationcategory() {
		axios.get(`${API_ROOT}customercategory`)
			.then(response => {
				console.log(response)
				this.setState({ categorylistnav: response.data.success });
			})
			.catch(function (error) {
				console.log(error);
			})
	}

	jqueryCall() {
		// $(".category-list").click(function () {
		// 	$(this).toggleClass("inner-category-list");
		// });
		$(document).ready(function () {
			$(window).scroll(function () {
				if ($(document).scrollTop() > 150) {
					$(".Cate").addClass("test");
				} else {
					$(".Cate").removeClass("test");
				}
			});
		});
		$(document).ready(function () {
			$(window).scroll(function () {
				if ($(document).scrollTop() > 2000) {
					$(".Cate").removeClass("test");
				}
				else {

				}
			});
		});
	}

	changeulli(e, i) {
		// console.log(e);
		// console.log(i);
		this.state.activeclass = !this.state.activeclass;
		this.setState({divid:e, slug:''})
	}

	render() {
		return (
			<div className="Product">
				<section class="contact-img-area">
					<div class="container">
						<div class="row">
							<div class="col-md-12 text-center">
								<div class="con-text">
									<h2 class="page-title">Categoires</h2>
										<p><Link to={`/`}>Home <i class="fa fa-angle-right"></i></Link> {this.state.SecretGardenCategory!=''?<>{this.state.SecretGardenCategory}</>:''}</p>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section class="design-area home-five" >
					<div class="container">
						<div className="row">
					
							<div className="col-md-3">
								
								<div className="Cate">
									<div className="inner-cate">
										<h2>Categoires</h2>
										<ul>
										
											{this.state.categorylistnav.map((object, i) => {
												return (
													<>
														{object.parent_id == 0 && (
															<li key={i+1}>
																<div className={`category-list ${this.state.divid==i+1 || (this.state.slug!='' && this.state.slug==object.slug ) ? 'inner-category-list' : ""}`}  onClick={this.changeulli.bind(this, i+1)}>
																	<div className="cat-content">
																		{ this.state.slug!='' && this.state.slug==object.slug  ? <Link  ><b>{object.name} </b> </Link>: <Link  >{object.name} </Link> }
																		
																		
																		<span><MdKeyboardArrowRight /></span>
																		
																		</div>
																	<ul className="innerlist-content">
																		{this.state.categorylistnav.map((item, j) => {
																			return (
																				<>
																					{item.parent_id == object.id && (
																						<li key={j}>	<Link to={`/product/${object.slug}/${item.slug}`}>
																							{item.name}</Link>
																						</li>
																					)}
																				</>

																			)
																		})
																		}
																	</ul>
																</div>
															</li>

														)}
													</>
												)
											})
											}


										</ul>
									</div>
								</div>
							</div>
							<div className="col-md-9">
								<div class="product-content">
									<div className="inner-product-content">
										<span className="s-title">{this.state.SecretGardenCategory!=''?<>{this.state.SecretGardenCategory}</>:'Product Categories'}</span>
									</div>
									<div className="sub-item-listing">
										<div className="row">

										{this.state.loader==true?<img src="/images/6.gif" />:<>{this.state.categoryList.length>0?<>	{this.state.categoryList.map((object, i) =>
												<div className="col-md-4 mb-4">
													<div className="inner-product border">
													<Link to={`/product/${object.perantslug}/${object.slug}`}>{object.subimage != null ? <img src={IMAGE_URL + object.subimage} className="img-fluid" /> : <img src="/images/s1.jpg" className="img-fluid" />}</Link>
														<Link to={`/product/${object.perantslug}/${object.slug}`} className="price">{object.subname}</Link>
													</div>
												</div>
											)}</>:<h2>New Categoires Coming Soon!</h2>}</>}

										
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		)
	}
}
export default SubCategory;