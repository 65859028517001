import React from 'react'
import {Link} from 'react-router-dom'
import { FaRegCalendarAlt } from "react-icons/fa";
import axios from 'axios';
import { API_ROOT, IMAGE_URL } from '../../Services/Api-config';
// import { MdCardTravel } from "react-icons/md";
// import { FaComment } from "react-icons/fa"
// import { IoIosArrowForward } from "react-icons/io"
import $ from 'jquery';

const initialState = { blog:"", bloglist:[] }
class Blog extends React.Component{

	constructor(props) {
		super(props);
		this.state = initialState;
	}

	componentDidUpdate = (prevProps) => {
		if (this.props.match.params.slug !== prevProps.match.params.slug) {
			this.blogcallbyslug(this.props.match.params.slug)
		}
	}

	componentDidMount(){
		 this.jquerycall();
		//  console.log(this.props.match.params.slug);
		 this.blogcallbyslug(this.props.match.params.slug)
	}

	blogcallbyslug(slug){
		axios.get(`${API_ROOT}webblogbyslug/` + slug)
		.then(response => {
			// console.log(response);
			this.setState({ blog: response.data.success, bloglist: response.data.bloglist});
			window.scrollTo(0, 0)
		})
		.catch(function (error) {
			console.log(error);
		})
	}

	jquerycall(){
		$(document).ready(function() { 
			$(".read-btn").click(function() { 
				$(".blog-text").toggleClass("gfg"); 
			}); 
		});
	}

	render(){
		return(
			<div className="About">
				<section class="contact-img-area">
		            <div class="container">
		                <div class="row">
		                    <div class="col-md-12 text-center">
		                        <div class="con-text">
		                            <h2 class="page-title">Blog</h2>
		<p><a href="#">Home <i class="fa fa-angle-right"></i></a>{this.state.blog.blogtitle}</p>
		                        </div>
		                    </div>
		                </div>
		            </div>
		        </section>
		        <section className="blog-section">
		        	<div className="container">
		        		<div className="inner-blog-box">
		        			<div className="left-inner-box">
		        				<div className="inside-blog-content">
		        					<div className="blog-img">
								{ this.state.blog.blogphote!=''? <img src={IMAGE_URL+this.state.blog.blogphote} /> :
		        						<img src="/images/003.jpg" alt="user img" /> }
		        					</div>
		        					<div className="blog-details">
		        						<ul>
		        							<li>
		        								<label>Title </label>
		<p>{this.state.blog.blogtitle}</p>
		        							</li>
		        							{/* <li>
		        								<label>title 2</label>
		        								<p> title content 2</p>
		        							</li>
		        							<li>
		        								<label>title 3</label>
		        								<p> title content 3</p>
		        							</li> */}
		        							<li>
		        								<label>small description</label>
		        								<div dangerouslySetInnerHTML={{ __html: this.state.blog.smalldescription }} />
		        							</li>
		        						</ul>
		        					</div>
		        				</div>
		        				<div className="below-blog-content">
		        					<h3>{this.state.blog.blogtitle}</h3>
									<div dangerouslySetInnerHTML={{ __html: this.state.blog.fulldescription }} />
		        				</div>
		        			</div>
		        			<div className="recent-inner-box">
		        				<div className="recent-blog">
		        					<h4>recent posts</h4>
		        					<ul>

										{this.state.bloglist.map((object, i) =>
		        						<li>
		        							<Link to={`/blog/${object.slug}`}><h3 title={object.blogtitle}><div dangerouslySetInnerHTML={{ __html: object.blogtitle.substring(0, 30)+'...' }} /></h3></Link>
		        							<p><FaRegCalendarAlt /> {object.blogdate}</p>
										</li>
										)}
		        					</ul>
		        				</div>
		        			</div>
		        		</div>
		        	</div>
		        </section>
			</div>
			)
	}
}
export default Blog;