import React from 'react';
import axios from 'axios';
import { API_ROOT } from '../../Services/Api-config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class RegisterForm extends React.Component {
    constructor() {
      super();
      this.state = {
        fields: {},
        errors: {}
      }

      this.handleChange = this.handleChange.bind(this);
      this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);

    };

    handleChange(e) {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;
      this.setState({
        fields
      });

    }

    async   submituserRegistrationForm(e) {
      e.preventDefault();
      // console.log(this.validateForm())
      if (this.validateForm()) {
        // console.log(this.state.fields)
        const customHeaders = {
          'content-type': 'application/json',
        };
        await axios.post(`${API_ROOT}contactform`, { name: this.state.fields.username, phone: this.state.fields.mobileno, email: this.state.fields.emailid, message: this.state.fields.message}, customHeaders).then(response => {
          // console.log(response)
          if (response.data.success) {
            this.notify();
            this.state.fields = {username: "", phone:"", email:"", message:""}
          } 
        }).catch(error => {
          console.log(error);
        });
      }

    }

    validateForm() {

      let fields = this.state.fields;
      let errors = {};
      let formIsValid = true;

      if (!fields["username"]) {
        formIsValid = false;
        errors["username"] = "*Please enter your username.";
      }

      if (typeof fields["username"] !== "undefined") {
        if (!fields["username"].match(/^[a-zA-Z ]*$/)) {
          formIsValid = false;
          errors["username"] = "*Please enter alphabet characters only.";
        }
      }

      if (!fields["emailid"]) {
        formIsValid = false;
        errors["emailid"] = "*Please enter your email-ID.";
      }

      if (typeof fields["emailid"] !== "undefined") {
        //regular expression for email validation
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(fields["emailid"])) {
          formIsValid = false;
          errors["emailid"] = "*Please enter valid email-ID.";
        }
      }

      if (!fields["mobileno"]) {
        formIsValid = false;
        errors["mobileno"] = "*Please enter your mobile no.";
      }

      if (typeof fields["mobileno"] !== "undefined") {
        if (!fields["mobileno"].match(/^((?:[+?0?0?968]+)(?:\s?\d{2})(?:\s?\d{8}))$/)) {
          formIsValid = false;
          errors["mobileno"] = "*Please enter valid mobile no.";
        }
      }

      this.setState({
        errors: errors
      });
      return formIsValid;


    }

    notify = () => toast.success("Thanks for Contact Us !");


  render() {
    return (
     <div className="main-registration-container">
     <div id="register">
        <form method="post"  name="userRegistrationForm"  onSubmit= {this.submituserRegistrationForm} >
        <label>Name</label>
        <input type="text" name="username" defaultValue={this.state.fields.username!=''?this.state.fields.username:''} onChange={this.handleChange} />
        <div className="errorMsg">{this.state.errors.username}</div>
        <label>Email</label>
        <input type="text" name="emailid" defaultValue={this.state.fields.emailid !=''?this.state.fields.emailid:''} onChange={this.handleChange}  />
        <div className="errorMsg">{this.state.errors.emailid}</div>
        <label>phone Number</label>
        <input type="text" name="mobileno" defaultValue={this.state.fields.mobileno!=''?this.state.fields.mobileno:''} onChange={this.handleChange}   />
        <div className="errorMsg">{this.state.errors.mobileno}</div>
        <label>Message</label>
        <textarea name="message" defaultValue={this.state.fields.message!=''?this.state.fields.message:''} onChange={this.handleChange}></textarea>
        <button>send Message</button>
        </form>

        <ToastContainer position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover />
    </div>
</div>
    

      );
  }


}


export default RegisterForm;