import React from 'react'
import {Link} from 'react-router-dom'
import ChangePasswordForm from './../common/change-password'

class ChangePassword extends React.Component{
	render(){
		return(
			<div className="About">
				<section class="contact-img-area">
		            <div class="container">
		                <div class="row">
		                    <div class="col-md-12 text-center">
		                        <div class="con-text">
		                            <h2 class="page-title">Change Password</h2>
		                            <p><a href="#">Home <i class="fa fa-angle-right"></i></a> login</p>
		                        </div>
		                    </div>
		                </div>
		            </div>
		        </section>
		        <section class="design-area home-five" >
				  	<div class="container">
				  		<ChangePasswordForm />		   
				  	</div>
				</section>
			</div>
			)
	}
}
export default ChangePassword;