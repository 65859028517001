import React from 'react'
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import MyProfile from './my-profile'
import Dashboard from './dashboard'
import Order from './order'
import Review from './Review'
import ChangePasswordForm from './../common/change-password'
// import { IMAGE_URL } from '../../Services/Api-config';
import { withRouter } from 'react-router'
import {connect} from "react-redux"

const initialState = { username: '', userlastname: '', useremail: '', userimage:'' }
class Profile extends React.Component {
	
	componentWillMount() {
		if (localStorage.getItem("webuser") != undefined) {
			const userID = JSON.parse(localStorage.getItem("webuser"));
			this.setState({ username: userID.name, userlastname: userID.last_name, useremail: userID.email, userimage:userID.image  })
		}

		// console.log(localStorage.getItem("webuser"));
	}

	componentDidMount = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	}

	handleLanguage = (user) => {
		console.log(user);
        this.setState({userimage: user.image, username: user.name, userlastname:user.last_name});
	}
	
	handleLogout = (event) => {
		localStorage.clear();
		this.props.emptyproduct();
		this.props.history.push("/");
	}

	render() {
		return (
			<div className="Profile">
				<div className="container">
					<div className="profile-inner-content">
						<Tabs>
							<div className="row">
								<div className="col-md-3">
									<div className="tab-left-content">
										<div className="dashboard-content">
											{/* <div>
											{this.state.userimage !=null ? <img src={IMAGE_URL+this.state.userimage} /> : <img src="images/p003.jpg" alt="dashboard-img" />}	
											</div> */}
											<div>
												<h2>{this.state.username} {this.state.userlastname}</h2>
												{/* <p>{this.state.useremail}</p> */}
											</div>
										</div>
										<TabList>
											<Tab>Dashboard</Tab>
											<Tab>My Profile</Tab>
											<Tab>Change Password</Tab>
											<Tab>Order Details</Tab>
											<Tab>Review</Tab>
											<Tab><Link onClick={this.handleLogout}>Logout</Link></Tab>
										</TabList>
									</div>
								</div>
								<div className="col-md-9">
									<div className="tab-right-content">
										<TabPanel>
											<Dashboard />
										</TabPanel>
										<TabPanel>
											<MyProfile onSelectLanguage={this.handleLanguage} />
										</TabPanel>
										<TabPanel>
											<ChangePasswordForm />
										</TabPanel>
										<TabPanel>
											<Order />
										</TabPanel>
										<TabPanel>
											<Review />
										</TabPanel>
									</div>
								</div>
							</div>
						</Tabs>
					</div>
				</div>
			</div>
		)
	}
}

const mapsStateToProps=(state)=>{
	return {
	  data: state
	}
  }
  
  const mapDispatchToProps = (dispatch)=> {
	return {
	 emptyproduct: () =>
		dispatch({
		  type: "EMPTY"
		})
  
	};
  }
export default connect(mapsStateToProps, mapDispatchToProps)(withRouter(Profile));



