import React from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import moment from 'moment'
import { MdDelete } from "react-icons/md";
import StarRatings from 'react-star-ratings';
import { API_ROOT, IMAGE_URL } from '../../Services/Api-config';
import $ from 'jquery'

const initialState = { totalreview: [], userid: 0, deleteitem:"" }
class Review extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentWillMount = () => {
        if (localStorage.getItem("webuser") != undefined) {
            const userID = JSON.parse(localStorage.getItem("webuser"));
            this.setState({ userid: userID.id })
        }
    }

    componentDidMount = () => {
        this.OrderView();
        
    }

    deleteconform = (val) => {
        this.setState({ deleteitem: val })
        $(".delete-content-box").toggleClass("delta")
     }
  
     cancel = (e) =>{
        $(".delete-content-box").removeClass("delta")
        this.setState({ successmsg: "" })
     }

    OrderView() {

        axios.get(`${API_ROOT}customview/` + this.state.userid)
            .then(response => {
                console.log(response.data);
                if (response.data.success) {
                    this.setState({ totalreview: response.data.success });
                }

            })
            .catch(function (error) {
                console.log(error);
            })
    }

    deleteriew(e){   
        $(".delete-content-box").toggleClass("delta")
        axios.post(`${API_ROOT}customviewdelete`, {'userid':this.state.userid, 'reviewid':this.state.deleteitem})
        .then(response => {
            // console.log(response.data);    
            if(response.data.success){
                this.OrderView();
            }

        })
        .catch(function (error) {
            console.log(error);
        })

    }


    render() {
        return (
            <div className="order">
                <h2>orders Details</h2>
                <div className="table-heading-box">
                    <table>
                        <thead>
                            <tr className="price-table">
                                <th>s.no.</th>
                                <th>product Name</th>
                                <th>Review Title</th>
                                <th>Review Rating</th>
                                <th>Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.totalreview.length > 0 && this.state.totalreview.map((object, i) => {
                                return (
                                    <tr className="price-amount" key={i}>
                                        <td>{i + 1}</td>
                                        <td><Link to={`/product-details/${object.slug}`} >{object.productname}</Link></td>
                                        <td>{object.reviewbody}</td>
                                        <td> <StarRatings
                                            starRatedColor="orange"
                                            rating={(parseFloat(object.reviewvalue))}
                                            starDimension="19px"
                                            starSpacing="1px"
                                        /></td>
                                        <td>{moment(object.created_at).format("MMMM Do YYYY")}</td>
                                        <td><Link onClick={(e) => { this.deleteconform(object.reviewid) }}><MdDelete /></Link>
                                                 <div class="delete-content-box">
                                                      <div className="inside-delete-content-box">
                                                          <p>Do You Really Want To Delete</p>
                                                          <div class="btn-opt">
                                                              <button class="cancel" onClick={i => this.cancel(i)}>cancel</button>
                                                              <button onClick={i => this.deleteriew(i)}>ok</button>
                                                          </div>
                                                      </div>
                                                   </div>
                                        </td>

                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
export default Review;