import React from 'react'
import {Link} from 'react-router-dom'
import { FaUserAlt } from "react-icons/fa";
// import { FiLogIn } from "react-icons/fi";
// import { FiLogOut } from "react-icons/fi";
import { FaSearch } from "react-icons/fa";
import { IoIosWallet } from "react-icons/io";
import { TiThMenu } from "react-icons/ti";
import { withRouter } from 'react-router'
import $ from 'jquery';
import { API_ROOT, IMAGE_URL } from '../../Services/Api-config';
import axios from 'axios';
import {connect} from "react-redux"
// import NamesContainer from '../common/namecontainer'


const initialState = { categorylist: [], categorychild: [],loggedIn:true, searchName:"", searchNameError:"",names : [],searchTerm : '',seturl:'', productimageurl:'' };
class Header extends React.Component{
	constructor(props) {
		super(props);
		this.state = initialState;
		this.searchtextInput = React.createRef();
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount = () => {
		this.load();
		this.jqueryload();
	}

	editSearchTerm = (e) => {
		axios.post(`${API_ROOT}webhomeserach`, { serachname: e.target.value}).then(response => {
				if(response.data.success){
					this.setState({names : response.data.success})
					if(response.data.serachtype=="product"){
						this.setState({seturl:'product-details'})
					}
					if(response.data.serachtype=="category"){
						this.setState({seturl:'product'})
					}

				}else{
					this.setState({names:[]})
				}
			 }).catch(error => {
				console.log(error);
			 });
			 
			 this.setState({searchTerm : e.target.value})
	  }
	
	dynamicSearch = () => {
	  return this.state.names.filter(name => name.slug.toLowerCase().includes(this.state.searchTerm.toLowerCase()))
	}
	

	load() {
		this.setState({searchName:"", searchNameError:""})
		axios.get(`${API_ROOT}customercategory`)
			.then(response => {
				console.log(response.data.success);
				this.setState({ categorylist: response.data.success });
			})
			.catch(function (error) {
				console.log(error);
			})

			axios.get(`${API_ROOT}webgetallheadermenuimage`)
			.then(response => {
				console.log(response.data.success);
				this.setState({ productimageurl: response.data.success });
			})
			.catch(function (error) {
				console.log(error);
			})
	}
		jqueryload () {
				$(".nav-icon").click(function(){
				  $(".nav-bar-list").toggleClass("show-bar");
				});
				$(".search-icon").click(function(){
				  $(".search-box").toggleClass("search-show");
				});

				$(".hovvy").hover(function(){
				  $(".category").toggleClass("show-now");
				  }, function(){
				  $(".category").toggleClass("show-now");
				});

				$(".hovvy1").hover(function(){
					$(".category1").toggleClass("show-now");
					}, function(){
					$(".category1").toggleClass("show-now");
				  });
				
				
		} 

		Searchbar = () => {
			this.setState({searchNameError:"", names:[], searchTerm:"" })
			document.querySelector(".overlay2").style.display = "block";		
	}
	closebtn = () => {
		document.querySelector(".overlay2").style.display = "none";
	}


	validate = () => {
		if (this.state.searchName=='') {
			this.setState({ searchNameError :"search box can not be blank" });
			this.searchtextInput.current.focus();
		   return false;
		}
  		return true;
	 };

	 appendtotextbox(e, name){
		this.setState({searchTerm:name.name, searchName:name.slug})
	 }

	 handleSubmit(event) {
		const formcheck = this.validate();
		event.preventDefault();
		if(formcheck){
			document.querySelector(".overlay2").style.display = "none";
			this.props.history.push(`/${this.state.seturl}/${this.state.searchName}`)
		}
	 }

	 changeclass(){
		$(".category").addClass("show-now2");
	 }

	 onMouseEnterHandler () {
		$(".category").removeClass("show-now2");
    }

	render(){
		return(
			<div className="Header">
				<div className="container header-contain">
					<div className="row  d-flex justify-content-between align-items-center">
						<div className="col-md-2 logo-box">
								<Link to="/"><img src="/images/logo.png" alt="lalas logo"  className="img-fluid"/></Link>
							</div>
								<div className="col-md-8 nav-bar">
									<ul className="nav-bar-list">
										<li><Link to="/">Home</Link></li>
										<li className="hovvy" onMouseEnter={this.onMouseEnterHandler}><Link to="/sub-category">products</Link>
											<div className="category">
											   <div className="container">
											      <div class="mega-menu menu-minus">
											         <div class="tas1 tas3">
													 {this.state.categorylist.map((object, i) => {
														return (
															<>
																{object.parent_id == 0 && (
																	<div class="tas">
																		<h3 class="hedding-border"> <Link to={`/sub-category/${object.slug}`}>
																						 {object.name}</Link> </h3>

																		{this.state.categorylist.map((item, i) => {
																			return (
																				<>
																					{item.parent_id == object.id && (
																						  <Link onClick={this.changeclass} to={`/product/${object.slug}/${item.slug}`}>
																						 {item.name}</Link>
																					)}
																				</>

																			)
																		})
																		}


																	</div>
																)}
															</>

														)
													})
													}
													 </div>
											         <div class="tas4">
											            <div class="menu-img im3">
														<a href={this.state.productimageurl.urlone}>
											               <img alt="" src={IMAGE_URL + this.state.productimageurl.imageone} /></a> 
											            </div>
											            <div class="menu-img im3">
														<a href={this.state.productimageurl.urltwo}>
											               <img alt="" src={IMAGE_URL + this.state.productimageurl.imagetwo} /></a> 
											            </div>
											         </div>
											      </div>
											   </div>
											</div>
										</li>
										<li><Link to="/about">about us</Link></li>
										<li><Link to="/contact">contact us</Link></li>
									</ul>
								</div>
								<div className="col-md-2 header-right-box">
								<ul className="d-flex justify-content-end header-icon">
								{
   ( (!localStorage.getItem('webuser')) || (this.state.loggedIn === false)  )	 ? 
<li className="icon-list"><Link to="/login" ><FaUserAlt /></Link></li>
 : <li className="icon-list"><Link to="/profile"  ><FaUserAlt /></Link></li>
}



								<li className="icon-list search-icon" ><Link onClick={this.Searchbar}><FaSearch /></Link>
										<div id="myOverlay" class="overlay2" >
										  <span class="closebtn"  title="Close Overlay" onClick={this.closebtn}>×</span>
										  <div class="overlay-content">
										    <form onSubmit={this.handleSubmit} class="search-form-box">
										      <div>
											  <input type="text" ref={this.searchtextInput} placeholder="Search.." name="search" value ={this.state.searchTerm} onChange = {this.editSearchTerm} />
											  <div style={{ fontSize: 12, color: "red" }}>
													{this.state.searchNameError}
												</div>
											  </div>
										      <button type="submit">Search</button>
											  <ul>
											<li className="search-tem-list">{this.state.names.map(name => <div onClick={e => this.appendtotextbox(e, name)}>{name.name}</div>)}</li>
											</ul>
										    </form>
											
				
										  </div>
										</div>	
								</li>
								<li><Link to="/cart"><IoIosWallet /><span>{this.props.data.shoppingCart ? this.props.data.shoppingCart.length : 0}</span></Link></li>
								<li className="nav-icon"><Link ><TiThMenu /></Link></li>
		
								</ul>
								</div>
								</div>
							</div>	
			</div>
			)
	}
}

const mapsStateToProps=(state)=>{
	return {
	  data: state
	}
  }
  
  const mapDispatchToProps = (dispatch)=> {
	return {
	  addtocartproduct: (id, product) =>
		dispatch({
		  type: "ADD_TO_CART_SESSION",
		  id: id,
		  products: product
		}),
		incproduct: id =>
		dispatch({
		  type: "INC",
		  id: id
		}),
		decproduct: id =>
		dispatch({
		  type: "DEC",
		  id: id
		})
  
	};
  }

  
export default connect(mapsStateToProps, mapDispatchToProps)(withRouter(Header));

