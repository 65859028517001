import React from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import { compareAsc, format } from 'date-fns'
import { API_ROOT, IMAGE_URL } from '../../Services/Api-config';

const initialState = { orderitem: [], shippingdetail: "", userid:0 }
class Order extends React.Component {
	constructor(props) {
		super(props);
		this.state = initialState;
	}

	componentWillMount =()=>{
		if (localStorage.getItem("webuser") != undefined) {
			const userID = JSON.parse(localStorage.getItem("webuser"));
			this.setState({ userid: userID.id })
		  }
	}

	componentDidMount = () => {
		this.OrderView();
	
	}

	OrderView() {

		axios.get(`${API_ROOT}customerorder/`+this.state.userid)
			.then(response => {
				console.log(response.data);
				if(response.data.success){
					this.setState({ orderitem: response.data.success });
				}

			})
			.catch(function (error) {
				console.log(error);
			})
	}
	render() {
		return (
			<div className="order">
				<h2>orders Details</h2>
				<div className="table-heading-box">
					<table>
						<thead>
							<tr className="price-table">
								<th>s.no.</th>
								<th>date</th>
								<th>order id</th>
								<th>name</th>
								<th>mobile</th>
								<th>payment</th>
								<th>status</th>
							</tr>
						</thead>
						<tbody>
							{this.state.orderitem.length>0 && this.state.orderitem.map((object, i) => {
								return (
									<tr className="price-amount" key={i}>
										<td>{i + 1}</td>
										<td>
											{format(new Date(object.created_at), 'dd-MM-yyyy')}</td>
								<td><Link to={`/invoice/${object.orderid}`}>{object.orderid}</Link></td>
										
										<td>{object.firstname} {object.lastname}</td>
										<td>{object.mobile}</td>
										<td>cod</td>
										<td>
											<select className="sorted" >
												<option className="opt" >waiting</option>
												<option className="opt" >approved</option>
												<option className="opt" >delivered </option>
												<option className="opt" >cancel</option>
											</select>
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</div>
			</div>
		)
	}
}
export default Order;