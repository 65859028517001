const istate = { shoppingCart: [], totalPrice: 0, message: '', qty: 0 }

const reducer = (state = istate, action) => {
    let { shoppingCart, totalPrice, qty } = state;
    let product;
    let index;
    let updatedPrice;
    let updatedQty;

    switch (action.type) {
        
        case 'ADD_TO_CART_LOGIN':
            // console.log(action.allproducts);
            const checklogin = shoppingCart.find(cart => cart.productid === action.productid);
            if (checklogin) {
                product = shoppingCart.find(product => product.productid === action.productid);
                index = shoppingCart.findIndex(product => product.productid === action.productid);
                product.qty =  parseInt(product.qty) + parseInt(action.qty);
                updatedQty =  parseInt(qty) + parseInt(action.qty);
                totalPrice = totalPrice + (product.price*action.qty);
                shoppingCart[index] = product;
                localStorage.setItem('shoppingCart', JSON.stringify([...shoppingCart]));
                localStorage.setItem('qty', updatedQty);
                localStorage.setItem('totalPrice', totalPrice);
                return { shoppingCart: [...shoppingCart], totalPrice: totalPrice, message: '', qty: parseInt(updatedQty) }
            }else{
                product = action.allproducts.find(product => product.productid == action.productid);
                product['qty'] =  parseInt(action.qty);
                updatedQty = parseInt(qty) + parseInt(action.qty);
                totalPrice = totalPrice + (product.price*action.qty);
                localStorage.setItem('shoppingCart', JSON.stringify([product, ...shoppingCart]));
                localStorage.setItem('qty', updatedQty);
                localStorage.setItem('totalPrice', totalPrice);
                return { shoppingCart: [product, ...shoppingCart], totalPrice: totalPrice, message: '', qty: parseInt(updatedQty) };

            }
            break;

        case 'ADD_TO_CART_SESSION':
            // console.log(action.products);
            return { shoppingCart: [action.products, ...shoppingCart], totalPrice: parseInt(localStorage.getItem("totalPrice")), message: '', qty: parseInt(localStorage.getItem("qty")) };

            break;
        case 'ADD_TO_CART':
            // console.log(action.totalqty)
            localStorage.removeItem("shoppingCart");
            localStorage.removeItem("qty");
            localStorage.removeItem("totalPrice");
            const check = shoppingCart.find(cart => cart.productid === action.productid);
            if (check) {
                localStorage.setItem('shoppingCart', [...shoppingCart]);
                localStorage.setItem('qty', qty);
                localStorage.setItem('totalPrice', totalPrice);
                return { shoppingCart: [...shoppingCart], totalPrice, message: 'This is product is already in the cart!', qty };
            } else {
                product = action.products.find(product => product.productid == action.productid);
                product['qty'] = action.totalqty;
                updatedQty = parseInt(action.totalqty);
                let setloacal = JSON.stringify([product, ...shoppingCart]);
                localStorage.setItem('shoppingCart', setloacal);
                localStorage.setItem('qty', updatedQty);
                localStorage.setItem('totalPrice', parseInt(totalPrice) + parseInt(product.price)*parseInt(updatedQty));
                return { shoppingCart: [product, ...shoppingCart], totalPrice: parseInt(totalPrice) + parseInt(product.price)*parseInt(updatedQty), message: '', qty: updatedQty };
            }
            break;
        case 'DELETE_PRODUCT':
            localStorage.removeItem("shoppingCart");
            localStorage.removeItem("qty");
            localStorage.removeItem("totalPrice");
            const filtered = shoppingCart.filter(cart => cart.productid !== action.productid);
            product = shoppingCart.find(cart => cart.productid === action.productid);
            // setAllPrice(allPrice - product.price * product.qty);
            updatedPrice = parseInt(totalPrice) - parseInt(product.price) * parseInt(product.qty);
            updatedQty = parseInt(qty) - parseInt(product.qty);

            localStorage.setItem('shoppingCart', JSON.stringify([...filtered]));
            localStorage.setItem('qty', updatedQty);
            localStorage.setItem('totalPrice', updatedPrice);
            return { shoppingCart: [...filtered], totalPrice: updatedPrice, message: '', qty: updatedQty }
            break;

        case 'INC':
            localStorage.removeItem("shoppingCart");
            localStorage.removeItem("qty");
            localStorage.removeItem("totalPrice");
            console.log('Inc run');
            product = shoppingCart.find(product => product.productid === action.productid);
            index = shoppingCart.findIndex(product => product.productid === action.productid);
            product.qty = parseInt(product.qty) + 1;
            updatedQty = parseInt(qty) + 1;
            totalPrice = parseInt(totalPrice) + parseInt(product.price);
            // console.log(product, totalPrice);
            shoppingCart[index] = product;
            localStorage.setItem('shoppingCart', JSON.stringify([...shoppingCart]));
            localStorage.setItem('qty', updatedQty);
            localStorage.setItem('totalPrice', totalPrice);
            return { shoppingCart: [...shoppingCart], totalPrice: parseInt(totalPrice), message: '', qty: parseInt(updatedQty) }
            break;

        case 'DEC':
            localStorage.removeItem("shoppingCart");
            localStorage.removeItem("qty");
            localStorage.removeItem("totalPrice");
            product = shoppingCart.find(product => product.productid === action.productid);
            index = shoppingCart.findIndex(product => product.productid === action.productid);
            if (product.qty > 1) {
                console.log('Yes graer');
                product.qty = parseInt(product.qty) - 1;
                updatedPrice = parseInt(totalPrice) - parseInt(product.price);
                updatedQty = parseInt(qty) - 1;
                shoppingCart[index] = product;
                // console.log("afer dec", shoppingCart);
                localStorage.setItem('shoppingCart', JSON.stringify([...shoppingCart]));
                localStorage.setItem('qty', updatedQty);
                localStorage.setItem('totalPrice', updatedPrice);
                return { shoppingCart: [...shoppingCart], totalPrice: updatedPrice, message: '', qty: updatedQty };
            } else {
                localStorage.setItem('shoppingCart', JSON.stringify([...shoppingCart]));
                localStorage.setItem('qty', updatedQty);
                localStorage.setItem('totalPrice', totalPrice);
                return { shoppingCart: [...shoppingCart], totalPrice: parseInt(totalPrice), message: '', qty: parseInt(qty) }
            }
            break;

        case 'EMPTY':
            localStorage.removeItem("shoppingCart");
            localStorage.removeItem("qty");
            localStorage.removeItem("totalPrice");
            sessionStorage.removeItem('useCouponeCode')
            sessionStorage.removeItem('useCouponeCodeId')
            sessionStorage.removeItem('useCouponType')
            sessionStorage.removeItem('useCouponValue')
            // localStorage.setItem('shoppingCart', []);
            return { shoppingCart: [], totalPrice: 0, message: '', qty: 0 }
            break;
        default:
            return state;

    }
}

export default reducer;