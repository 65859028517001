import React from 'react'
// import { Link } from 'react-router-dom'
import { API_ROOT } from '../../Services/Api-config';
import axios from 'axios';
import $ from 'jquery';

const initialState = { name: "", lastname: "", phone: "", email: "", selectedFile: null, loading: false, nameError: "", lastnameError: "", phoneError: "", emailError: "", iamgeError: "", selectedFileError: "", successmsg: "", userid: 0, image: "" }
class MyProfile extends React.Component {
	constructor(props) {
		super(props);
		this.state = initialState;
		this.handleSubmit = this.handleSubmit.bind(this);
		this.firstnameInput = React.createRef();
		this.lastnameInput = React.createRef();
		this.mobileInput = React.createRef();

	}

	componentWillMount() {
		if (localStorage.getItem("webuser") != undefined) {
			const userID = JSON.parse(localStorage.getItem("webuser"));
			this.setState({ userid: userID.id })
		}
	}

	componentDidMount = () => {
		this.OrderView();

	}

	OrderView() {
		axios.get(`${API_ROOT}costomerdetail/` + this.state.userid)
			.then(response => {
				console.log(response.data);
				this.setState({ name: response.data.success.name, lastname: response.data.success.last_name, phone: response.data.success.phone, email: response.data.success.email, successmsg: "" });

			})
			.catch(function (error) {
				console.log(error);
			})
	}

	onFileChange = event => {
		if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
			this.setState({ selectedFileError: 'Please select valid image.' });
			return false;
		}

		this.setState({ selectedFile: event.target.files[0], selectedFileError: "" });
		return true;
	};

	validate = () => {
		let nameError = "";
		let lastnameError = "";
		let phoneError = "";
		let emailError = "";

		if (this.state.phone == '') {
			phoneError = "Mobile Number is Required";
			this.mobileInput.current.focus();
			$("#mobileno").addClass("errorclass");
		}else{
			$("#mobileno").removeClass("errorclass");
		}
		

		if (this.state.lastname == '') {
			lastnameError = "Last Name is Required";
			this.lastnameInput.current.focus();
			$("#lastname").addClass("errorclass");
		}else{
			$("#lastname").removeClass("errorclass");
		}
		
		if (this.state.name == '') {
			nameError = "First Name is Required";
			this.firstnameInput.current.focus();
			$("#firstname").addClass("errorclass");
		}else{
			$("#firstname").removeClass("errorclass");
		}

		if (this.state.email == '') {
			emailError = "email can not be blank";
		}
		if (this.state.email.includes('@')) {
			this.setState(
				{ nameError: "Invalid email id" }
			)
		}

		if (nameError || lastnameError || phoneError || emailError) {
			this.setState({ nameError, lastnameError, phoneError, emailError });
			return false;
		}

		return true;
	};

	handleSubmit(event) {
		event.preventDefault();
		//   alert()
		this.setState({ successmsg: "" })
		const isValid = this.validate();
		if (isValid) {
			this.setState(
				{ nameError: "", lastnameError: "", phoneError: "", emailError: "", selectedFileError: "" }
			)
			this.setState({ loading: true });
			const formData = new FormData();
			if (this.state.selectedFile != null && this.state.selectedFile != '') {
				formData.append("file", this.state.selectedFile);
			}
			formData.append("name", this.state.name);
			formData.append("last_name", this.state.lastname);
			formData.append("email", this.state.email);
			formData.append("phone", this.state.phone);
			formData.append("userid", this.state.userid)
			axios.post(`${API_ROOT}webcostomeredit`, formData).then(response => {
				this.setState({ image: '' });
				this.setState({ loading: false });
				this.setState({ successmsg: 'Profile is Successfully Updated', selectedFile: "" });
				this.load();
			}).catch(error => {
				console.log(error);
			});
		}
	}

	
	render() {
		return (
			<div>
				<h2>My profile</h2>
				<div className="profile-details-box">
					<div className="left-details-box">
					
					</div>
					
					<form onSubmit={this.handleSubmit}>

					<div className="right-details-box">
						<ul>
							<li>
								<h3>First Name</h3>
								<input type="text" ref={this.firstnameInput} id="firstname" value={this.state.name} onChange={(event) => { this.setState({ name: event.target.value }) }} />
								<div style={{ fontSize: 12, color: "red" }}>
									{this.state.nameError}
								</div>

							</li>
							<li>
								<h3>Last Name</h3>
								<input type="text" ref={this.lastnameInput} id="lastname" value={this.state.lastname} onChange={(event) => { this.setState({ lastname: event.target.value }) }} />
								<div style={{ fontSize: 12, color: "red" }}>
									{this.state.lastnameError}
								</div>

							</li>
							<li>
								<h3>mobile no</h3>
								<input type="text" ref={this.mobileInput} id="mobileno" value={this.state.phone} onChange={(event) => { this.setState({ phone: event.target.value }) }} />
								<div style={{ fontSize: 12, color: "red" }}>
									{this.state.phoneError}
								</div>
							</li>
							<li>
								<h3>Email</h3>
								{this.state.email} 
							</li>
							<li>
								{this.state.loading == true ? <img scr="/images/6.gif" /> : ''}
							</li>
							<li>
								<div style={{color:'green'}}>{this.state.successmsg}</div>
							</li>
							<li className="edit-btn">
								<input type="submit" value="Update" />
							</li>
						</ul>
					</div>
					</form>
				</div>
			</div>
		)
	}
}
export default MyProfile;